import React from "react";
import { RightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import "./Leads.style.scss";

export const LeadsFromSameCompany = (props) => {
  const { leadId, leadName, jobTitle, isNavigateArrow, lastName } = props;
  const navigate = useNavigate();
  const commonNameColors = ["#11A7D9", "#73D4F4", "rgba(223, 89, 130, 0.80)"];

  const getRandomColor = () => {
    return commonNameColors[
      Math.floor(Math.random() * commonNameColors.length)
    ];
  };

  const handleShowIndividualLead = (leadId) => {
    navigate(`/Lead/${leadId}`);
  };

  return (
    <div className="leads-from-same-company-container">
      <div
        className="lead-row"
        onClick={() => handleShowIndividualLead(leadId)}
      >
        <div className="lead-row-name-container">
          <div
            style={{
              backgroundColor: getRandomColor(),
            }}
            className="lead-row-name-circle"
          >
            <p>{leadName?.charAt(0).toUpperCase()}</p>
          </div>
          <div className="lead-row-name-text-wrapper">
            <div className="lead-row-name-text">
              {leadName} {lastName}
            </div>
            <div className="lead-row-job-title">{jobTitle}</div>
          </div>
        </div>

        {isNavigateArrow ? <RightOutlined /> : null}
      </div>
    </div>
  );
};
