import { Image, Tabs } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import Dashboard from "../../assets/icons/leads.svg";
import PieChart from "../../assets/icons/pieChart.svg";
import {
  companyPath,
  leadPath,
} from "../../common/constants/pagepath/PagePath";

const { TabPane } = Tabs;
const TabsNavbar = ({ selectedKey, onSelect }) => {
  if (selectedKey === companyPath) {
    selectedKey = leadPath;
  }
  return (
    <Tabs
      type="card"
      activeKey={selectedKey}
      onChange={onSelect}
      tabBarStyle={{
        paddingTop: "35px",
      }}
    >
      <TabPane
        tab={
          <Link to="/leads/individual">
            <div className="tab-wrapper">
              <Image src={Dashboard} preview={false} />
              <p>Leads</p>
            </div>
          </Link>
        }
        key="/leads/individual"
      />
      <TabPane
        tab={
          <Link to="/dashboard">
            <div className="tab-wrapper">
              <Image src={PieChart} preview={false} />
              <p>Dashboard</p>
            </div>
          </Link>
        }
        key="/dashboard"
      />
    </Tabs>
  );
};

export default TabsNavbar;
