import { Image } from "antd";
import React, { useEffect, useState } from "react";
import "./Dashboard.style.scss";
import TotalLeads from "../../assets/icons/totalLeads.svg";
import { TopContentInterest } from "./TopContentInterest";
import { TopTopicInterest } from "./TopTopicInterest";
import NatureDistribution from "./NatureDistribution";
import JobDistribution from "./JobDistribution";
import { apiClient } from "../../api/apiService";
import { apiErrorHandling } from "../../common/utils/helpers";

function DashboardTab() {
  const [totalLeads, setTotalLeads] = useState(0);
  const [highestScore, setHighestScore] = useState("");

  const fetchHighestScore = async () => {
    try {
      const response = await apiClient.get("/dashboard/highest-leads-source");
      setHighestScore(response.data.source);
    } catch (error) {
      apiErrorHandling(error);
      console.error(error);
    }
  };

  const fetchTotalLead = async () => {
    try {
      const response = await apiClient.get("/dashboard/leadcount");
      setTotalLeads(response.data.count);
    } catch (error) {
      apiErrorHandling(error);
      console.error(error);
    }
  };

  useEffect(() => {
    fetchTotalLead();
    fetchHighestScore();
  }, []);

  return (
    <div className="dashboard-tab-wrapper">
      <div className="dashboard">Dashboard</div>
      <div className="dashboard-tab-child-one">
        <div className="lead-content-info-wrapper">
          <div className="total-lead-container">
            <div>
              <Image src={TotalLeads} preview={false} />
            </div>
            <div>
              <span className="lead-number">{totalLeads}</span>
              <span className="lead-number-text">Total Leads</span>
            </div>
          </div>
          <div className="highestSourceContainer">
            <p className="highest-score-text">Highest Source of leads</p>
            <p className="highest-scroe-value">{highestScore}</p>
          </div>
        </div>
        <div className="lead-content-interest-wrapper">
          <TopContentInterest />
          <TopTopicInterest />
        </div>
      </div>
      <div className="dashboard-tab-child-two">
        <div className="jobFunContainer">
          <JobDistribution />
        </div>
        <div className="businessNatureContainr">
          <NatureDistribution />
        </div>
      </div>
    </div>
  );
}

export default DashboardTab;
