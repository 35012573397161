import React, { useEffect, useState } from "react";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Bar } from "react-chartjs-2";
import "./JobFunction.style.scss";
// import data from "../../common/constants/jobDistribuiton.json";
import { apiClient } from "../../api/apiService";
import { apiErrorHandling } from "../../common/utils/helpers";

Chart.register(CategoryScale, ChartDataLabels);

function JobDistribution() {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "",
        data: [],
        backgroundColor: [],
        borderWidth: 1,
      },
    ],
  });

  const jobDistrubtionfetchData = async () => {
    try {
      const response = await apiClient.get(`dashboard/jobtitledistribution`);

      setChartData({
        labels: response.data.data.map((item) => item.job_function),
        datasets: [
          {
            label: "",
            data: response.data.data.map((item) => item.lead_count),
            backgroundColor: "#11A7D9",
            // response.data.data.map((item) => {
            //   if (item.lead_count > 7) {
            //     return "#11A7D9";
            //   } else {
            //     return "#73D4F4";
            //   }
            // }),
            borderWidth: 1,
            borderRadius: 4,
          },
        ],
      });
    } catch (error) {
      apiErrorHandling(error);
      console.error(error);
    }
  };

  useEffect(() => {
    jobDistrubtionfetchData();
  }, []);

  const scrollbarlength = {
    overflowX: chartData.labels.length > 10 ? "auto" : "",
    overflowY: "none",
    maxWidth: chartData.labels.length > 10 ? "700px" : "auto",
    width: chartData.labels.length > 10 ? "700px" : "100%",
  };

  const barContainer = {
    width: chartData.labels.length > 10 ? "1250px" : "",
  };

  const options = {
    scales: {
      x: {
        grid: {
          display: false,
          borderColor: "#ffffff",
        },
        ticks: {
          font: {
            size: 12,
            weight: 700,
            family: "Open Sans",
          },
          color: "#000",
        },
      },

      y: {
        grid: {
          display: false,
          borderColor: "#ffffff",
        },
        ticks: {
          display: false,
        },
      },
    },
    plugins: {
      datalabels: {
        display: false,
      },
      title: {
        display: false,
        text: "",
      },
      labels: {
        offset: 20,
        formatter: (labels) => labels.join("\n"),
      },
      legend: {
        display: false,
        labels: {
          font: {
            size: 12,
            weight: 700,
            color: "#000",
          },
        },
      },
    },
    responsive: true,
    aspectRatio: 2,
    maintainAspectRatio: false,
  };

  return (
    <div className="jobMainContainer">
      <p className="jobTitle">Job function distribution between leads</p>
      <div style={scrollbarlength} className="scrollBar">
        <div className="bar-container" style={barContainer}>
          <Bar data={chartData} options={options} />
        </div>
      </div>
    </div>
  );
}

export default JobDistribution;
