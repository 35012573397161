import React, { useEffect } from "react";
import "./Modal.style.scss";
import { Button, Modal } from "antd";

export const StandardModal = (props) => {

  useEffect(()=>{
   window.scrollTo(0,0)
  },[]);

  const {
    message,
    onCancel,
    onContinue,
    showModal,
    onCancelMsg,
    onContinueMsg,
    children,
    centered,
    closable,
    ModalWidth,
    title,
    Modalheight
  } = props;
  return (
    <Modal
      title={title}
      open={showModal}
      footer={null}
      onCancel={onCancel}
      centered={centered}
      closable={closable}
      width={ModalWidth}
      height={Modalheight}
    >
      {children}
      <div className="filter-check-modal-wrapper">
        <h2>{message}</h2>
      </div>
      <div className="filter-check-button-wrapper">
        {onCancelMsg && (
          <Button className="continue-button apply-button" onClick={onCancel}>
            {onCancelMsg}
          </Button>
        )}

        {onContinueMsg && (
          <Button className="continue-button" onClick={onContinue}>
            {onContinueMsg}
          </Button>
        )}
      </div>
    </Modal>
  );
};
