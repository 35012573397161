import React, { useEffect, useState } from "react";
import "../../../pages/Leads/IndividualLeads/IndividualLeads.style.scss";
import "./Modal.style.scss";
import { Button, Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import { useNavigate } from "react-router-dom";
import { apiClient } from "../../../api/apiService";
import RatingHistory from "../RatingHistory";
import { apiErrorHandling, handleRateText } from "../../utils/helpers";
import { leadPath } from "../../constants/pagepath/PagePath";
export const RateLeadModal = (props) => {
  const {
    showRateLead,
    toggleRateLead,
    handleRateLead,
    leadName,
    modalType,
    comment,
    leadId,
    setComment,
    leadRate,
    ratingHistoryData,
  } = props;

  const [selectedBtn, setSelectedBtn] = useState(0);

  const navigate = useNavigate();
  const handleCommentChange = (e) => {
    let value = e.target.value;
    if (value === "" || value[0] !== " ") {
      setComment(e.target.value);
    }
  };

  const handleBackToLeads = () => {
    handleRateLead(selectedBtn);
    navigate(leadPath);
  };

  const handleSaveRating = () => {
    handleRateLead(selectedBtn);
    toggleRateLead();
  };

  const handleCancel = () => {
    toggleRateLead();
    setComment("");
    if (leadRate === 0) {
      setSelectedBtn(0);
    } else {
      fetchRatingHistory(leadId);
    }
  };

  const fetchRatingHistory = async (leadId) => {
    try {
      const response = await apiClient.get(`rate-lead-history/${leadId}`);
      if (response.data?.data[0]?.rating > 0) {
        setSelectedBtn(response.data?.data[0]?.rating);
      }
    } catch (err) {
      apiErrorHandling(err);
      console.error(err);
    }
  };

  useEffect(() => {
    if (leadRate !== 0) {
      setSelectedBtn(leadRate);
    }

    if (ratingHistoryData.length === 0) {
      setSelectedBtn(0);
    }
  }, [leadId, leadRate, ratingHistoryData]);

  return (
    <Modal
      centered
      open={showRateLead}
      closable={false}
      footer={null}
      width={702}
    >
      <div className="rate-lead-dialog-wrapper">
        <div className="rate-lead-title">
          <p>
            Lead rating for <span>{leadName}</span>
          </p>
          <Button className="close-rate" onClick={handleCancel}>
            <CloseOutlined className="close-btn-icon" />
          </Button>
        </div>

        <div className="rate-lead-activity-wrapper">
          {ratingHistoryData.length !== 0 && (
            <div style={{ width: "100%" }}>
              <RatingHistory ratingHistoryData={ratingHistoryData} />
            </div>
          )}

          <div className="rate-title-in-relavent-wrapper">
            <p className="rate-title-in-relavent">
              How relevant is this lead to you?{" "}
              <span style={{ color: "red" }}>*</span>
            </p>
            <div className="rate-btn-wrapper">
              <Button
                className={
                  selectedBtn === 1
                    ? "rate-btn-label-container selected-rate-btn"
                    : "rate-btn-label-container"
                }
                onClick={() => setSelectedBtn(1)}
              >
                <div>
                  <p className="rate-number">1</p>
                </div>
                <div>
                  <p
                    style={{ color: `${handleRateText(1).color}` }}
                    className="rate-text"
                  >
                    {handleRateText(1).text}
                  </p>
                </div>
              </Button>

              <Button
                className={
                  selectedBtn === 2
                    ? "rate-btn-label-container selected-rate-btn"
                    : "rate-btn-label-container"
                }
                onClick={() => setSelectedBtn(2)}
              >
                <div>
                  <p className="rate-number">2</p>
                </div>
                <p
                  style={{ color: `${handleRateText(2).color}` }}
                  className="rate-text"
                >
                  {handleRateText(2).text}
                </p>
              </Button>

              <Button
                className={
                  selectedBtn === 3
                    ? "rate-btn-label-container selected-rate-btn"
                    : "rate-btn-label-container"
                }
                onClick={() => setSelectedBtn(3)}
              >
                <div>
                  <p className="rate-number">3</p>
                </div>
                <p
                  style={{ color: `${handleRateText(3).color}` }}
                  className="rate-text"
                >
                  {handleRateText(3).text}
                </p>
              </Button>

              <Button
                className={
                  selectedBtn === 4
                    ? "rate-btn-label-container selected-rate-btn"
                    : "rate-btn-label-container"
                }
                onClick={() => setSelectedBtn(4)}
              >
                <div>
                  <p className="rate-number">4</p>
                </div>
                <p
                  style={{ color: `${handleRateText(4).color}` }}
                  className="rate-text"
                >
                  {handleRateText(4).text}
                </p>
              </Button>

              <Button
                className={
                  selectedBtn === 5
                    ? "rate-btn-label-container selected-rate-btn"
                    : "rate-btn-label-container"
                }
                onClick={() => setSelectedBtn(5)}
              >
                <div>
                  <p className="rate-number">5</p>
                </div>
                <p
                  style={{ color: `${handleRateText(5).color}` }}
                  className="rate-text"
                >
                  {handleRateText(5).text}
                </p>
              </Button>
            </div>
          </div>
          <div className="feedback-wrapper">
            <p className="feedback">
              Do you have any feedback? <span style={{ color: "red" }}>*</span>
            </p>
            <TextArea
              placeholder="Why did you give the lead this rating?..."
              style={{ minHeight: "100px" }}
              value={comment}
              onChange={handleCommentChange}
            />
          </div>
        </div>
        <div className="rate-btn-submit-wrapper">
          {modalType === "backToLead" ? (
            <p className="skip-btn" onClick={() => navigate(leadPath)}>
              Skip
            </p>
          ) : (
            <p className="skip-btn" onClick={handleCancel}>
              Skip
            </p>
          )}

          <Button
            className="cancel-btn"
            onClick={
              modalType === "backToLead" ? handleBackToLeads : handleSaveRating
            }
            disabled={selectedBtn === 0 || comment === ""}
            style={
              selectedBtn === 0 ||
              (comment !== "" && {
                backgroundColor: "#002244",
                color: "white",
              })
            }
          >
            Save rating
          </Button>
        </div>
      </div>
    </Modal>
  );
};
