import React, { useEffect, useState } from "react";
import { Button, Form, Typography } from "antd";
import Password from "antd/es/input/Password";
import { useNavigate } from "react-router-dom";
import "../Registration/Registration.style.scss";
import { validRegex } from "../../../common/constants/regex/regex";
import { apiClient } from "../../../api/apiService";
import { StandardModal } from "../../../common/components/Modal/StandardModal";
import { apiErrorHandling } from "../../../common/utils/helpers";
const { Text } = Typography;

const ChangePassword = () => {
  const [formData, setFormData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [errorMessage, setErrormessage] = useState({
    currentPasswordError: "",
    newPasswordError: "",
    confirmPasswordError: "",
  });

  const [passwordModal, setPasswordModal] = useState(false);
  const togglePasswordModal = () => {
    setPasswordModal(!passwordModal);
  };
  const validatePassword = (value) => {
    const errors = { ...errorMessage };
    if (!validRegex.passswordRegex.test(value)) {
      errors.newPasswordError =
        "Must contain at least 1 number and 1 uppercase and lowercase letter, 1 special character and at least 8 character";
    } else {
      errors.newPasswordError = "";
    }

    if (formData.confirmPassword && formData.confirmPassword !== value) {
      errors.confirmPasswordError = "Password do not match";
    } else {
      errors.confirmPasswordError = "";
    }

    setErrormessage(errors);
  };

  const navigate = useNavigate();

  const handleOnChangePassword = () => {
    navigate("/");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const errors = { ...errorMessage };

    if (!formData.currentPassword.trim()) {
      errors.currentPasswordError = "Enter valid password";
    }

    if (!formData.newPassword.trim()) {
      errors.newPasswordError = "Enter valid password";
    }

    if (!formData.confirmPassword.trim()) {
      errors.confirmPasswordError = "Enter valid password";
    }

    try {
      const response = await apiClient.post("/change-password", {
        old_password: formData.currentPassword,
        password: formData.newPassword,
      });
      if (response) {
        togglePasswordModal();
      }
    } catch (error) {
      if (
        error.response.status === 401 &&
        error.response.data.message === "Incorrect old password"
      ) {
        errors.currentPasswordError = "Invalid current password";
      } else if (error.response.status === 401) {
        errors.newPasswordError =
          "The current and new password should not be same";
      }
      apiErrorHandling(error);
    }

    setErrormessage(errors);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value.trim(),
    });

    if (name === "newPassword") {
      validatePassword(value);
    }

    if (name !== "newPassword") {
      setErrormessage({
        ...errorMessage,
        [`${name}Error`]: "",
      });
    }
  };

  const validtateConfirmPassword = () => {
    const errors = { ...errorMessage };
    if (
      formData.confirmPassword &&
      formData.newPassword &&
      formData.confirmPassword !== formData.newPassword
    ) {
      errors.confirmPasswordError =
        "Confirm password does not match New Password";
    } else {
      errors.confirmPasswordError = "";
    }

    setErrormessage(errors);
  };

  const validateNewPassword = () => {
    const errors = { ...errorMessage };
    if (
      formData.currentPassword &&
      formData.newPassword &&
      formData.currentPassword === formData.newPassword
    ) {
      errors.newPasswordError =
        "The current and new password should not be same";
    } else if (formData.newPassword) {
      validatePassword(formData.newPassword);
    } else {
      errors.newPasswordError = "";
    }

    setErrormessage(errors);
  };

  const goBackToLeads = () => {
    navigate(-1);
  };

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        handleOnChangePassword();
      }
    };

    window.addEventListener("keydown", handleEsc);
  }, [navigate]);
  return (
    <div className="registraton-wrapper change-password-wrapper">
      <Form
        layout="vertical"
        className="registration-container change-password-container"
      >
        <div className="header change-password-header">
          <Text className="text">Change your password</Text>
        </div>
        <Form.Item label="Current Password" required={true}>
          <Password
            placeholder="Enter current password"
            name="currentPassword"
            value={formData.currentPassword}
            onChange={handleInputChange}
          />
          {errorMessage.currentPasswordError && (
            <p className="errorMsg">{errorMessage.currentPasswordError}</p>
          )}
        </Form.Item>
        <Form.Item label="New Password" required={true}>
          <Password
            placeholder="Enter new password"
            name="newPassword"
            value={formData.newPassword}
            onChange={handleInputChange}
            onBlur={validateNewPassword}
          />
          {errorMessage.newPasswordError && (
            <p className="errorMsg">{errorMessage.newPasswordError}</p>
          )}
        </Form.Item>
        <Form.Item label="Confirm Password" required={true}>
          <Password
            placeholder="Confirm your password"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleInputChange}
            onBlur={validtateConfirmPassword}
          />
          {errorMessage.confirmPasswordError && (
            <p className="errorMsg">{errorMessage.confirmPasswordError}</p>
          )}
        </Form.Item>
        <div className="export-btns-wrapper">
          <Button className="refine-btn" onClick={goBackToLeads}>
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={
              !formData.currentPassword ||
              !formData.confirmPassword ||
              !formData.newPassword ||
              errorMessage.currentPasswordError ||
              errorMessage.confirmPasswordError ||
              errorMessage.newPasswordError
            }
            className="submit submit-btn"
          >
            Submit
          </Button>
        </div>
      </Form>
      <StandardModal
        showModal={passwordModal}
        message="Password Updated Successfully"
        onCancelMsg="Back to login"
        onCancel={handleOnChangePassword}
      />
    </div>
  );
};

export default ChangePassword;
