import { Dropdown, Image } from "antd";
import React, { useState, useEffect } from "react";
import { LandingPage } from "../Dashboard/Landing/LandingPage";
import styled from "styled-components";
import profile from "../../assets/Profile/image.png";
import DashboardTab from "../LeadsDashboard/DashboardTab";
import { useLocation, useNavigate } from "react-router-dom";
import "./Navbar.style.scss";
import { UniqueModal } from "../../common/components/Modal/UniqueModal";
import { InviteTeamMembers } from "./InviteTeamMembers";
import {
  clearSelectedCompanyKeys,
  clearSelectedKeys,
  setCheckAll,
  setCompanyCheckAll,
  setCompanyPagination,
  setpagination,
} from "../../store/action";
import { useDispatch, useSelector } from "react-redux";
import TabsNavbar from "./TabsNavbar";
import { leadPath } from "../../common/constants/pagepath/PagePath";
import UserProfile from "./UserProfile";
import { apiErrorHandling } from "../../common/utils/helpers";

const TabContainer = styled.div`
  .ant-tabs {
    margin-right: 500px;
  }
  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #000000 !important;
  }

  .ant-tabs-top > .ant-tabs-nav::before {
    border: none;
  }

  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
    border: none;
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
    align-items: flex-start;
    justify-content: flex-start;
  }
  .ant-tabs-top > .ant-tabs-nav {
    margin-bottom: 0px;
  }
  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab {
    background-color: white;
  }
  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active {
    background-color: #dadada80;
    border-bottom: 0px solid #dadada80;
  }
`;
export const Navbar = () => {
  const [showInViteTeam, setShowInviteTeam] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [admin, setAdmin] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();
  const pagination = useSelector((state) => state.pagination);
  const companyPagination = useSelector((state) => state.companypagination);

  useEffect(() => {
    const storedAdmin = localStorage.getItem("admin");
    setAdmin(JSON.parse(storedAdmin));
    if (location.pathname === "/leads") {
      navigate(leadPath);
    }
  }, [location, navigate]);

  const handleNavSelect = (key) => {
    navigate(key);
  };

  const handleLogout = () => {
    navigate("/");
    dispatch(clearSelectedKeys());
    dispatch(setCheckAll(false));
    dispatch(clearSelectedCompanyKeys());
    dispatch(setCompanyCheckAll(false));
    dispatch(setpagination(1, pagination.pagesize));
    dispatch(setCompanyPagination(1, companyPagination.pageSize));
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    localStorage.removeItem("admin");
  };

  const menuItems = [
    {
      label: (
        <div
          onClick={(e) => {
            e.preventDefault();
            setShowProfile(true);
          }}
        >
          Profile
        </div>
      ),
      key: "0",
    },
    {
      label: <a href="/Change-password">Change Password</a>,
      key: "1",
    },
    {
      label: (
        <div
          onClick={(e) => {
            e.preventDefault();
            setShowInviteTeam(true);
          }}
        >
          Manage users
        </div>
      ),
      key: "2",
    },
    {
      label: <div onClick={handleLogout}>Logout</div>,
      key: "3",
    },
  ];

  const toggleForm = () => {
    setShowInviteTeam(!showInViteTeam);
  };

  const toggleProfile = () => {
    setShowProfile(!showProfile);
  };

  const filteredItems = !admin
    ? menuItems.filter((item) => item.key !== "2")
    : menuItems;

  const mainTabKey = location.pathname.includes("/individual")
    ? leadPath
    : location.pathname;

  return (
    <div>
      <div className="navbar-wrapper">
        <div className="navbar-logo">
          <h1>
            <span>IN</span>Touch
          </h1>
        </div>
        <TabContainer>
          <TabsNavbar
            selectedKey={mainTabKey}
            onSelect={(key) => handleNavSelect(key)}
          />
        </TabContainer>
        <div className="profile-dropdown">
          <Dropdown
            menu={{
              items: filteredItems,
            }}
            trigger={["click"]}
          >
            <div onClick={(e) => e.preventDefault()}>
              <Image src={profile} preview={false} />
            </div>
          </Dropdown>
        </div>
      </div>
      {location.pathname.startsWith("/leads") && <LandingPage />}
      {location.pathname === "/dashboard" && <DashboardTab />}
      <UniqueModal
        showModal={showInViteTeam}
        onCancel={toggleForm}
        message={"Manage Users"}
        closable={false}
      >
        <InviteTeamMembers />
      </UniqueModal>
      <UniqueModal
        showModal={showProfile}
        onChange={toggleProfile}
        message={"My profile"}
        closable={false}
        onCancel={toggleProfile}
        ModelHeight={"800"}
      >
        <UserProfile onCancelButton={toggleProfile} />
      </UniqueModal>
    </div>
  );
};
