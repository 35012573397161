import React, { useEffect, useState } from "react";
import "./landing.style.scss";
import FilterContainer from "./filtersidebar/FilterContainer";
import LeadsContainer from "./leads/LeadsContainer";
import { apiClient } from "../../../api/apiService";
import { useLocation } from "react-router-dom";
import {
  companyPath,
  leadPath,
} from "../../../common/constants/pagepath/PagePath";
import { apiErrorHandling } from "../../../common/utils/helpers";

export const LandingPage = () => {
  const [filterData, setFilterData] = useState([]);
  const location = useLocation();

  const [selectedItems, setSelectedItems] = useState({
    searchName: "",
    jobtitle: "",
    topic: "",
    country: "",
    nature_of_business: "",
    campaign: "",
  });

  const handleFetchData = async () => {
    try {
      const responses = await Promise.all([
        apiClient.get(`/topics`),
        apiClient.get(`/leadSource`),
        apiClient.get(`/countries`),
        apiClient.get(`/jobTitle`),
        apiClient.get(`/natureOfBusiness`),
      ]);

      const [
        topicsRes,
        leadSource,
        countriesRes,
        jobTitleRes,
        natureOfBusinessRes,
      ] = responses;

      topicsRes.data.key = "topic";
      leadSource.data.key = "campaign";
      countriesRes.data.key = "country";
      jobTitleRes.data.key = "jobtitle";
      natureOfBusinessRes.data.key = "nature_of_business";

      setFilterData([
        topicsRes.data,
        leadSource.data,
        countriesRes.data,
        jobTitleRes.data,
        natureOfBusinessRes.data,
      ]);
    } catch (error) {
      apiErrorHandling(error);
      console.error(error);
    }
  };

  useEffect(() => {
    handleFetchData();
    setSelectedItems({
      searchName: "",
      jobtitle: "",
      topic: "",
      country: "",
      nature_of_business: "",
      campaign: "",
    });
  }, []);

  const subTabKey = location.pathname.includes("/individual")
    ? leadPath
    : location.pathname.includes("/company")
    ? companyPath
    : leadPath;

  return (
    <div className="landing-container">
      <div className="landing-first-child">
        <FilterContainer
          filterData={filterData}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          activeKeyIndicator={subTabKey}
        />
      </div>
      <div
        className="landing-second-child"
        style={{ height: "inherit", width: "100%" }}
      >
        <LeadsContainer subTabKey={subTabKey} selectedItems={selectedItems} />
      </div>
    </div>
  );
};
