import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "./IndividualLeads.style.scss";
import {
  Button,
  Checkbox,
  ConfigProvider,
  Image,
  Pagination,
  Spin,
  Table,
} from "antd";
import {
  CaretDownOutlined,
  CaretUpOutlined,
  DownOutlined,
  UpOutlined,
} from "@ant-design/icons";
import exportIcon from "../../../assets/icons/exportLeads.svg";
import { useNavigate } from "react-router-dom";
import { StandardModal } from "../../../common/components/Modal/StandardModal";
import { apiClient } from "../../../api/apiService";
import { UniqueModal } from "../../../common/components/Modal/UniqueModal";
import { useDispatch, useSelector } from "react-redux";
import {
  clearSelectedKeys,
  setCheckAll,
  setSelecteKeys,
  setpagination,
} from "../../../store/action";
import { leadPath } from "../../../common/constants/pagepath/PagePath";
import { apiErrorHandling } from "../../../common/utils/helpers";

const TableContainer = styled.div`
  .ant-table-wrapper table {
    width: 100%;
    padding-bottom: 50px;
  }
  .ant-table-wrapper table > tbody > tr {
    cursor: pointer;
  }
  .ant-table-wrapper .ant-table-thead > tr > th {
    text-transform: math-auto;
  }
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan])::before {
    background-color: white;
  }
  .ant-pagination-item-active {
    background-color: #11a7d9;
    border-radius: 17px;
    color: white;

    a {
      color: white;
    }
  }
`;
const IndividualLeads = ({
  individualFitersData,
  selectedItems1,
  data,
  activeKey,
}) => {
  // const [dataSource, setdatasource] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [expandedRows, setExpandedRows] = useState({});
  // const [totalItems, setTotalItems] = useState(0);
  const [isNatureOfBusiness, setIsNatureOfBusiness] = useState(false);
  const [isCampaign, setIsCampagin] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [isFileExported, setIsFileExported] = useState(false);
  const [showFilterCheck, setShowFilterCheck] = useState(false);
  const [sortOrder, setSortOrder] = useState("");
  const [sortField, setSortField] = useState("");
  const [loading, setloading] = useState(true);

  const newSelectedRowKeys = useSelector((state) => state.selectedRowKeys);
  const pagination = useSelector((state) => state.pagination);
  const isAllSelected = useSelector((state) => state.checkAll);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchLeadsList = async (
    currentPage,
    pagesize,
    sortOrder,
    sortField
  ) => {
    const offset = (currentPage - 1) * pagesize;
    const limit = pagesize;

    try {
      const response = await apiClient.post("/leads", {
        offset,
        limit,
        lead_name: selectedItems1.searchName,
        lead_job_title: selectedItems1.jobtitle,
        lead_topic_interest: selectedItems1.topic,
        lead_country: selectedItems1.country,
        lead_source: selectedItems1.campaign,
        lead_nature_of_business: selectedItems1.nature_of_business,
        sortby: sortField,
        type: sortOrder,
      });

      if (selectedItems1.nature_of_business.length !== 0) {
        setIsNatureOfBusiness(true);
      }
      if (selectedItems1.campaign.length !== 0) {
        setIsCampagin(true);
      }
      data.setIndividualTotalItems(response.data.totalRecords);
      data.setIndivdualdatasource(response.data.data);
      if (isAllSelected) {
        const allKeys = response.data.data.map((item) => item.id);
        dispatch(setSelecteKeys([...newSelectedRowKeys, ...allKeys]));
      }
    } catch (error) {
      console.error(error);
      apiErrorHandling(error);
    } finally {
      setloading(false);
    }
  };

  const sortHandler = (inputsortField) => {
    if (inputsortField && sortField === inputsortField) {
      const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
      setSortOrder(newSortOrder);
    } else {
      setSortOrder("asc");
      setSortField(inputsortField);
    }
    if (pagination.current > 1) {
      dispatch(setpagination(1, pagination.pagesize));
    }
  };

  const handleExpand = (rowId, lead_source) => {
    lead_source.stopPropagation();

    setExpandedRowKeys((prevKeys) => {
      if (prevKeys.includes(rowId)) {
        return prevKeys.filter((key) => key !== rowId);
      } else {
        return [...prevKeys, rowId];
      }
    });

    setExpandedRows((prevRows) => ({
      ...prevRows,
      [rowId]: !prevRows[rowId],
    }));
  };

  const handleCollapse = () => {
    setExpandedRowKeys([]);
    setExpandedRows({});
  };

  const expandedRowRender = (record) => {
    const getColorbarStyle = (value) => {
      let color = "";
      let text = "";
      let width = "0%";

      switch (value) {
        case "Low":
          color = "#DF5A82";
          text = "Low";
          width = "20%";
          break;
        case "Medium":
          color = "#73D4F4";
          text = "Medium";
          width = "50%";
          break;
        case "High":
          color = "#11A7D9";
          text = "High";
          width = "80%";
          break;
        default:
          color = "#11A7D9";
          text = "unknown";
          width = "0%";
      }

      return {
        backgroundColor: color,
        width: width,
        text,
        padding: "4px 6px",
        borderRadius: "4px",
        marginLeft: "260px",
      };
    };

    return (
      <div className="buyer-intent-score-container">
        {record.topic.map((topic, index) => {
          return (
            <React.Fragment key={index}>
              {selectedItems1.topic.length === 0 && index <= 5 ? (
                <>
                  <div>
                    <p className="buyer-intent-item-table">{topic.name}</p>
                  </div>
                  <div style={getColorbarStyle(topic.scaled_score)}>
                    <span className="buyer-intent-rate">
                      <p style={{ color: "white" }}>
                        {getColorbarStyle(topic.scaled_score).text}
                      </p>
                    </span>
                  </div>
                </>
              ) : selectedItems1.topic.includes(topic.id) ? (
                <>
                  <div>
                    <p className="buyer-intent-item-table">{topic.name}</p>
                  </div>
                  <div style={getColorbarStyle(topic.scaled_score)}>
                    <span className="buyer-intent-rate">
                      <p style={{ color: "white" }}>
                        {getColorbarStyle(topic.scaled_score).text}
                      </p>
                    </span>
                  </div>
                </>
              ) : (
                <></>
              )}
            </React.Fragment>
          );
        })}
      </div>
    );
  };

  const onSelectChange = (id) => {
    dispatch(
      setSelecteKeys(
        newSelectedRowKeys.includes(id)
          ? newSelectedRowKeys.filter((selectedKey) => selectedKey !== id)
          : [...newSelectedRowKeys, id]
      )
    );
  };

  const getCurrentPageData = () => {
    const { current, pagesize } = pagination;
    const startIndex = (current - 1) * pagesize;
    const enIndex = startIndex + pagesize;
    return data.IndividualdataSource.slice(startIndex, enIndex);
  };

  const onSelectAll = (e) => {
    const isChecked = e.target.checked;
    const currentPageDataKeys = getCurrentPageData().map((data) => data.id);
    if (isChecked) {
      dispatch(setSelecteKeys([...newSelectedRowKeys, ...currentPageDataKeys]));
    } else {
      dispatch(clearSelectedKeys());
    }

    dispatch(setCheckAll(isChecked));
  };

  const columns = [
    {
      title: (
        <Checkbox
          onChange={onSelectAll}
          checked={isAllSelected}
          indeterminate={!isAllSelected && newSelectedRowKeys.length > 0}
        />
      ),
      dataIndex: "checkbox",
      key: "checkbox",
      render: (text, record) => (
        <Checkbox
          checked={newSelectedRowKeys.includes(record.id)}
          onChange={(e) => onSelectChange(record.id)}
          onClick={(e) => e.stopPropagation()}
        />
      ),
    },
    {
      key: "1",
      title: "Name",
      render: ({ name, lastname }) => `${name} ${lastname}`,
    },
    {
      key: "2",
      title: (
        <div
          onClick={() => sortHandler("jobfunction")}
          className="column-sort-wrapper"
        >
          <div>Job function</div>
          <span className="sorter">
            <CaretUpOutlined />
            <CaretDownOutlined />
          </span>
        </div>
      ),
      dataIndex: "jobfunction",
    },
    {
      key: "3",
      title: "Country",
      dataIndex: "country",
    },
    {
      key: "4",
      title: (
        <div
          onClick={() => sortHandler("company")}
          className="column-sort-wrapper"
        >
          <div>Company</div>
          <span className="sorter">
            <CaretUpOutlined />
            <CaretDownOutlined />
          </span>
        </div>
      ),
      dataIndex: "company",
    },
    {
      key: "5",
      title: "Nature of business",
      dataIndex: "industry",
    },
    {
      key: "7",
      title: "Topics of interest",
      dataIndex: "topic",
      render: (topic) => {
        return (
          <div className="topic-container">
            {topic.length > 2 ? (
              <>
                <div className="topic-item">{topic[0].name}</div>
                <div className="topic-item">{topic[1].name}</div>
                <div className="topic-item">+{topic.length - 2}</div>
              </>
            ) : (
              topic.map((item, index) => {
                return (
                  <div className="topic-item" key={index}>
                    {item.name}
                  </div>
                );
              })
            )}
          </div>
        );
      },
    },
    {
      key: "8",
      title: "Buyer intent score",
      dataIndex: "id",
      render: (id) => (
        <Button
          onClick={(e) => handleExpand(id, e)}
          className="expand-row-button"
        >
          <p className="expand-text">
            {expandedRows[id] ? "Hide score" : "Show score"}
          </p>

          {expandedRows[id] ? (
            <UpOutlined className="down-arrow" />
          ) : (
            <DownOutlined className="down-arrow" />
          )}
        </Button>
      ),
    },
  ];

  const handleTableChange = (page, pagesize) => {
    dispatch(setpagination(page, pagesize));
  };

  const handlePaginationChange = (page, pagesize) => {
    dispatch(setpagination(page, pagesize));
  };

  const exportSelectedData = async () => {
    try {
      setExporting(true);
      const payload = {
        allRequired: false,
        leadIds: newSelectedRowKeys,
      };

      const payloadForAllSelected = {
        allRequired: true,
        lead_name: selectedItems1.searchName,
        lead_job_title: selectedItems1.jobtitle,
        lead_topic_interest: selectedItems1.topic,
        lead_country: selectedItems1.country,
        lead_source: selectedItems1.campaign,
        lead_nature_of_business: selectedItems1.nature_of_business,
        sortby: sortField,
        type: sortOrder,
      };

      const response = await apiClient.post(
        "/export-leads",
        isAllSelected || newSelectedRowKeys.length === 0
          ? payloadForAllSelected
          : payload
      );

      const downloadFileAtUrl = (url) => {
        fetch(url)
          .then((response) => response.blob())
          .then((blob) => {
            const blobURL = window.URL.createObjectURL(new Blob([blob]));
            const fileName = url.split("/").pop();
            const aTag = document.createElement("a");
            aTag.href = blobURL;
            aTag.setAttribute("download", fileName);
            document.body.appendChild(aTag);
            aTag.click();
            aTag.remove();
          });

        setExporting(false);
        setShowFilterCheck(false);
        setIsFileExported(!isFileExported);
      };

      if (response.data.path) {
        downloadFileAtUrl(response.data.path);
      }
    } catch (error) {
      apiErrorHandling(error);
      console.error(error);
    }
  };

  const handleExportData = async () => {
    if (
      selectedItems1.campaign === "" &&
      selectedItems1.topic === "" &&
      selectedItems1.jobtitle === "" &&
      selectedItems1.country === "" &&
      selectedItems1.nature_of_business === "" &&
      (isAllSelected || newSelectedRowKeys.length === 0)
    ) {
      toggleShowFilter();
    } else {
      exportSelectedData();
    }
  };

  const handleRowClick = (leadId) => {
    navigate(`/Lead/${leadId}`);
  };

  const toggleShowFilter = () => {
    setShowFilterCheck(!showFilterCheck);
  };

  const toggleExportCheck = () => {
    setIsFileExported(!isFileExported);
  };

  useEffect(() => {
    setloading(true);
    if (activeKey === leadPath) {
      fetchLeadsList(
        pagination.current,
        pagination.pagesize,
        sortOrder,
        sortField
      ).then(() => {
        setloading(false);
      });
    }
    handleCollapse();
  }, [
    pagination.current,
    pagination.pagesize,
    selectedItems1,
    sortOrder,
    sortField,
  ]);

  const content = <div className="loader" />;
  if (loading) {
    return (
      <Spin tip="Loading..." size="large">
        {content}
      </Spin>
    );
  }

  return (
    <div>
      <ConfigProvider
        theme={{
          components: {
            Table: {
              headerBg: "#ffffff",
            },
          },
        }}
      >
        <div className="lead-table-wrapper">
          <h5>Total Leads: {data.individualtotalItems} </h5>

          <Button
            className="export-btn"
            onClick={handleExportData}
            loading={exporting}
          >
            <Image src={exportIcon} preview={false} />
            <div>
              <p className="export-leads-text">Export Leads</p>
            </div>
          </Button>
        </div>

        <TableContainer>
          <Table
            columns={
              (!isNatureOfBusiness && !isCampaign) ||
              (!selectedItems1.nature_of_business && !selectedItems1.campaign)
                ? columns.filter((col) => col.key !== "5" && col.key !== "6")
                : (isNatureOfBusiness && !isCampaign) ||
                  !selectedItems1.campaign
                ? columns.filter((col) => col.key !== "6")
                : (!isNatureOfBusiness && isCampaign) ||
                  !selectedItems1.nature_of_business
                ? columns.filter((col) => col.key !== "5")
                : columns
            }
            dataSource={data.IndividualdataSource}
            rowKey="id"
            pagination={false}
            onRow={(record) => ({
              onClick: () => handleRowClick(record.id),
            })}
            onChange={handleTableChange}
            expandedRowKeys={expandedRowKeys}
            expandedRowRender={expandedRowRender}
            onExpand={(record, e) => handleExpand(record.id, e)}
            expandable={{ expandIconColumnIndex: -1 }}
            rowHoverable={true}
          />
          <div className="pagination-wrapper">
            <Pagination
              current={pagination.current}
              pageSize={pagination.pagesize}
              showSizeChanger={false}
              onChange={handlePaginationChange}
              total={data.individualtotalItems}
            />
          </div>
        </TableContainer>
      </ConfigProvider>

      <StandardModal
        showModal={isFileExported}
        onCancel={toggleExportCheck}
        message={"File Exported Successfully"}
        onCancelMsg={"Ok"}
      />

      {/* <StandardModal
        message={"Do you want to continue without applying filter"}
        onCancel={toggleShowFilter}
        onContinue={exportSelectedData}
        showModal={showFilterCheck}
        onCancelMsg={"Apply Filters"}
        onContinueMsg={"Continue to Export"}
      /> */}

      <UniqueModal
        message={"Export Leads"}
        showModal={showFilterCheck}
        onCancel={toggleShowFilter}
        closable={false}
        ModalWidth={702}
      >
        <div className="export-modal-wrapper">
          <div className="export-modal-text-wrapper">
            <p>
              Leads selected for export are{" "}
              <span>{data.individualtotalItems} leads.</span>
            </p>
            <p>
              You can refine your selection more by applying filters or
              selecting individual leads of interests.
            </p>
          </div>
          <div className="export-btns-wrapper">
            <Button className="refine-btn" onClick={toggleShowFilter}>
              Refine Export
            </Button>
            <Button
              className="refine-btn export-now-btn"
              onClick={exportSelectedData}
            >
              Export Now
            </Button>
          </div>
        </div>
      </UniqueModal>
    </div>
  );
};

export default IndividualLeads;
