import { Button, Image, Space, notification } from "antd";
import React, { useEffect, useState } from "react";
import Remove from "../../assets/Profile/removeUser.jpg";
import { StandardModal } from "../../common/components/Modal/StandardModal";
import { apiClient } from "../../api/apiService";
import { apiErrorHandling } from "../../common/utils/helpers";
export const InviteTeamMembers = () => {
  const [isInvited, setisInvited] = useState(false);
  const [userList, setUserList] = useState([]);
  const [api, contextHolder] = notification.useNotification();

  const toggleModal = () => {
    setisInvited(!isInvited);
  };

  const fetchInvitedUsers = async () => {
    try {
      const response = await apiClient.get("/dashboard/manageuserinvitelists?");
      setUserList(response.data.data);
    } catch (err) {
      apiErrorHandling(err);
      console.err(err);
    }
  };

  const handleOk = () => {
    api.destroy();
  };
  const openNotification = (placement) => {
    const btn = (
      <Space>
        <Button type="text" size="small" onClick={handleOk}>
          Ok
        </Button>
      </Space>
    );
    api.error({
      message: ``,
      description: "User access removed",
      placement,
      closeIcon: false,
      duration: 0,
      btn,
    });
  };

  const removeAccess = async (userEmail) => {
    try {
      const response = await apiClient.post("/dashboard/removeuseraccess", {
        email: userEmail,
      });

      if (response) {
        openNotification("top");
        fetchInvitedUsers();
      }
    } catch (err) {
      apiErrorHandling(err);
      console.error(err);
    }
  };

  useEffect(() => {
    fetchInvitedUsers();
  }, []);
  return (
    <>
      {contextHolder}

      <div className="invite-team-member-container">
        {/* <div className="inivte-team-member-top-container">
        <p>Invite others by email</p>
        <div className="invite-team-member-input-container">
          <Input placeholder="Mohamed.magdy@gmail.com" className="input-box" />
          <Button className="send-btn" onClick={sendInvite}>
            Send Invite
          </Button>
        </div>
      </div> */}

        {userList.length !== 0 &&
          userList.map((user, index) => {
            return (
              <div key={index}>
                <div className="inivte-team-member-bottom-container">
                  <div className="lead-row-name-container">
                    <div
                      style={{
                        backgroundColor: "#4E85A3",
                      }}
                      className="lead-row-name-circle"
                    >
                      <p>{user?.useremail?.charAt(0).toUpperCase()}</p>
                    </div>
                    <div className="lead-row-name-text-wrapper">
                      {user?.username !== null && (
                        <div className="lead-row-name-text">
                          {user.username}
                        </div>
                      )}
                      <div className="lead-row-job-title">
                        {user?.useremail}
                      </div>
                    </div>
                  </div>
                  <div
                    className="remove-access-container"
                    onClick={() => removeAccess(user?.useremail)}
                  >
                    {user?.registered_user ? (
                      <>
                        <Image src={Remove} preview={false} />
                        <p>Remove access</p>
                      </>
                    ) : (
                      <p style={{ cursor: "context-menu" }}>Invitation sent</p>
                    )}
                  </div>
                </div>
              </div>
            );
          })}

        {userList.length === 0 && (
          <p>
            Your team members aren't invited to access InTouch. Need assistance,
            feel free to reach out to our support team at{" "}
            <a href="mailto:mailto:Liban.ibrahim@informa.com">
              Liban.ibrahim@informa.com
            </a>
          </p>
        )}

        <StandardModal
          showModal={isInvited}
          message={"Invitation sent"}
          onCancel={toggleModal}
          onCancelMsg={"Ok"}
        />
      </div>
    </>
  );
};
