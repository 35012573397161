import React, { useEffect, useState } from "react";
import { Image } from "antd";
import "./Dashboard.style.scss";
import Polygon from "../../assets/Profile/Polygon.svg";
import { apiClient } from "../../api/apiService";
import {
  capitalizeFirstLetter,
  apiErrorHandling,
} from "../../common/utils/helpers";

export const TopContentInterest = () => {
  const [topContent, setTopContent] = useState([]);

  const fetchTopContent = async () => {
    try {
      const response = await apiClient.get("/dashboard/topcontenttypeinterest");
      setTopContent(response.data.topic);
    } catch (error) {
      apiErrorHandling(error);
      console.error(error);
    }
  };

  useEffect(() => {
    fetchTopContent();
  }, []);

  return (
    <div className="content-type-interest-wrapper">
      <div>
        <p className="interest-text">Top content</p>
      </div>
      <div
        className={
          topContent[0] && !topContent[1] && !topContent[2]
            ? "interest-container interest-container2"
            : "interest-container"
        }
      >
        {topContent[0] && (
          <div>
            <div className="interest-btn content-btn-one ">
              <Image src={Polygon} preview={false} />
              <p>1st</p>
            </div>
            <div className="interest-label">
              {" "}
              {capitalizeFirstLetter(topContent[0])}
            </div>
          </div>
        )}

        {topContent[1] && (
          <div>
            <div className="interest-btn content-btn-two ">
              <Image src={Polygon} preview={false} />
              <p>2nd</p>
            </div>
            <div className="interest-label">
              {capitalizeFirstLetter(topContent[1])}
            </div>
          </div>
        )}

        {topContent[2] && (
          <div>
            <div className="interest-btn content-btn-three ">
              <Image src={Polygon} preview={false} />
              <p>3rd</p>
            </div>
            <div className="interest-label">
              {capitalizeFirstLetter(topContent[2])}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
