import React, { useEffect, useState } from "react";
import { Collapse } from "antd";
import "./FilterContainer.style.scss";
import { LeftOutlined } from "@ant-design/icons";
import {
  companyPath,
  leadPath,
} from "../../../../common/constants/pagepath/PagePath";

export default function CollapseableFilterOptions({
  filterData1,
  selectedItems1,
  setSelectedItems1,
  activeKeyIndicator1,
  clearAll,
}) {
  const [items, setItems] = useState([]);
  const [selectedChild, setSelectedChild] = useState({});

  const handleselectedChild = (label, selected) => {
    setSelectedChild((prevCount) => ({
      ...prevCount,
      [label]: selected.length,
    }));
  };

  useEffect(() => {
    setSelectedChild({});
  }, [clearAll, activeKeyIndicator1]);

  useEffect(() => {
    let tempArr = [];
    filterData1.forEach((item, i) => {
      const handleSelectedItems = (childElm) => {
        let tempSelectedItemObj = selectedItems1;

        if (item.label === "Topics") {
          let topicId = [];
          const arr = item?.children?.status
            ? item?.children.data
            : item?.children;

          topicId = arr
            .filter((obj) => childElm.includes(obj.label))
            .map((obj) => obj.id);

          tempSelectedItemObj.topic = topicId.length > 0 ? topicId : "";
        } else if (item.label === "Campaign") {
          tempSelectedItemObj.campaign = childElm.length > 0 ? childElm : "";
        } else if (item.label === "Country") {
          tempSelectedItemObj.country = childElm.length > 0 ? childElm : "";
        } else if (item.label === "Job Function") {
          let jobId = [];
          const arr = item?.children?.status
            ? item?.children.data
            : item?.children;
          if (activeKeyIndicator1 === leadPath) {
            jobId = arr
              .filter((obj) => childElm.includes(obj.label))
              .map((obj) => obj.id);
          } else {
            jobId = childElm;
          }
          tempSelectedItemObj.jobtitle = jobId.length > 0 ? jobId : "";
        } else if (item.label === "Nature Of the Business") {
          tempSelectedItemObj.nature_of_business =
            childElm.length > 0 ? childElm : "";
        }

        setSelectedItems1(tempSelectedItemObj);
      };

      const handleChildrenData = (data) => {
        if (data.length > 0) {
          data?.forEach((element) => {
            element["selected"] = false;
          });
          return data;
        } else {
        }
      };

      const handleTempObj = (item) => {
        return {
          key: `${item?.key}_${i}`,
          label: (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
              }}
            >
              {item?.label}{" "}
              {selectedChild[item.label] > 0 && (
                <span
                  style={{
                    color: "#11A7D9",
                    fontSize: "10px",
                    fontWeight: "700",
                    textAlign: "center",
                    marginLeft: "auto",
                  }}
                >
                  {selectedChild[item.label] !== 0
                    ? selectedChild[item.label]
                    : ""}{" "}
                  {"  "}
                  selected
                </span>
              )}
            </div>
          ),
          children: (
            <ChildFilteterItems
              clearAll1={clearAll}
              activeKeyIndicator={activeKeyIndicator1}
              handleSelectedItems={handleSelectedItems}
              handleselectedChild={(selected) =>
                handleselectedChild(item.label, selected)
              }
              childData={
                item.children?.status
                  ? handleChildrenData(item?.children.data)
                  : handleChildrenData(item?.children)
              }
            />
          ),
          style: {
            color: "#000",
            fontSize: "14px",
            fontWeight: 700,
            lineHeight: "normal",
          },
        };
      };
      if (activeKeyIndicator1 === companyPath) {
        if (item?.key !== "jobtitle") {
          let tempObj = handleTempObj(item);
          tempArr.push(tempObj);
        }
      } else {
        let tempObj = handleTempObj(item);
        tempArr.push(tempObj);
      }
    });
    setItems(tempArr);
  }, [
    filterData1,
    activeKeyIndicator1,
    clearAll,
    selectedItems1,
    selectedChild,
  ]);

  const onChange = (keys) => {
    console.log("dsfg", keys);
  };

  return (
    <div style={{ background: "#fff" }}>
      <Collapse
        className="custom-collaps"
        expandIconPosition="end"
        bordered={false}
        ghost
        accordion
        items={items}
        defaultActiveKey={["1"]}
        onChange={onChange}
        expandIcon={({ isActive }) => (
          <LeftOutlined rotate={isActive ? 90 : -90} />
        )}
      />
    </div>
  );
}

const ChildFilteterItems = ({
  childData,
  handleSelectedItems,
  activeKeyIndicator,
  clearAll1,
  handleselectedChild,
}) => {
  const [selectedChild, setSelectedChild] = useState([]);

  useEffect(() => {
    setSelectedChild([]);
  }, [activeKeyIndicator, clearAll1]);

  const handleClick = (e, key, item) => {
    try {
      setSelectedChild((previuseSelectedItem) => {
        const updatedData = previuseSelectedItem.includes(item.label)
          ? previuseSelectedItem.filter(
              (selectItem) => selectItem !== item.label
            )
          : [...previuseSelectedItem, item.label];

        handleSelectedItems(updatedData ?? "");

        handleselectedChild(updatedData);

        return updatedData;
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div
      className="parent-collaps"
      style={{
        gap: 6,
      }}
    >
      {childData &&
        childData.map((item, i) => {
          return (
            <div
              className="child-collap"
              key={`${item.key}_${item.label}_${i}`}
              style={{
                background: selectedChild.includes(item.label)
                  ? "#11A7D9"
                  : "rgba(17, 167, 217, 0.24)",
                color: selectedChild.includes(item.label) ? "white" : "",
              }}
              onClick={(e) =>
                handleClick(e, `${item.key}_${item.label}_${i}`, item)
              }
            >{`${item?.label}`}</div>
          );
        })}
    </div>
  );
};
