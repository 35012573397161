import React, { useEffect, useState } from "react";
import { Image } from "antd";
import "./Dashboard.style.scss";
import Polygon from "../../assets/Profile/Polygon.svg";
import { apiClient } from "../../api/apiService";
import {
  capitalizeFirstLetter,
  apiErrorHandling,
} from "../../common/utils/helpers";

export const TopTopicInterest = () => {
  const [topTopicInterest, setTopTopicInterest] = useState([]);

  const fetchTopicInterest = async () => {
    try {
      const response = await apiClient.get("/dashboard/top-interest-topics");
      setTopTopicInterest(response.data.topics);
    } catch (error) {
      apiErrorHandling(error);
      console.error(error);
    }
  };

  useEffect(() => {
    fetchTopicInterest();
  }, []);

  return (
    <div className="content-type-interest-wrapper">
      <div>
        <p className="interest-text">Top topics</p>
      </div>
      <div
        className={
          topTopicInterest[0] && !topTopicInterest[1] && !topTopicInterest[2]
            ? "interest-container interest-container2"
            : "interest-container"
        }
      >
        {topTopicInterest[0] && (
          <div>
            <div className="interest-btn interest-btn-one">
              <Image src={Polygon} preview={false} />
              <p>1st</p>
            </div>
            <div className="interest-label">
              {capitalizeFirstLetter(topTopicInterest[0])}
            </div>
          </div>
        )}
        {topTopicInterest[1] && (
          <div>
            <div className="interest-btn interest-btn-two">
              <Image src={Polygon} preview={false} />
              <p>2nd</p>
            </div>
            <div className="interest-label">
              {capitalizeFirstLetter(topTopicInterest[1])}
            </div>
          </div>
        )}
        {topTopicInterest[2] && (
          <div>
            <div className="interest-btn interest-btn-three ">
              <Image src={Polygon} preview={false} />
              <p>3rd</p>
            </div>
            <div className="interest-label">
              {capitalizeFirstLetter(topTopicInterest[2])}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
