import React, { useEffect, useState } from "react";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Bar } from "react-chartjs-2";
import { apiClient } from "../../../api/apiService";
import { Image, Spin } from "antd";
import "./IndividualLeads.style.scss";
import TopicBreakdown from "../../../assets/icons/topicBreakdown.svg";
import { apiErrorHandling } from "../../../common/utils/helpers";

Chart.register(CategoryScale, ChartDataLabels);
function Topicsbreakdown({ id, activeKey }) {
  const [topicBreakDownData, setTopicBreakDownData] = useState([]);
  const [currentFilter, setCurrentFilter] = useState(null);
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "",
        data: [],
        backgroundColor: [],
        borderWidth: 1,
      },
    ],
  });
  const [loading, setloading] = useState(false);

  const interactionfetchData = async () => {
    try {
      const response = await apiClient.get(`/lead-activity/topics/${id}`);
      setTopicBreakDownData(response.data.topics);
      const newaspRatio = response.data.topics.map((data) => {
        if (data.score.toLowerCase() === "low") {
          return 4;
        } else if (data.score.toLowerCase() === "medium") {
          return 6;
        } else {
          return 8;
        }
      });
      setChartData({
        labels: response.data.topics.map((data) => data.name),
        datasets: [
          {
            label: response.data.topics.map((data) => data.score),
            data: newaspRatio,
            borderWidth: 1,
            borderRadius: 4,
            backgroundColor: response.data.topics.map((data) => {
              if (data.mutual === true) {
                return "#11A7D9";
              } else {
                return "#B6D0E2";
              }
            }),
            maxBarLength: 5,
          },
        ],
      });
    } catch (error) {
      console.error(error);
      apiErrorHandling(error);
    } finally {
      setloading(false);
    }
  };

  const filterData = (isMutual) => {
    const filteredData = topicBreakDownData.filter(
      (data) => data.mutual === isMutual
    );

    const newaspRatio = filteredData.map((data) => {
      if (data.score.toLowerCase() === "low") {
        return 4;
      } else if (data.score.toLowerCase() === "medium") {
        return 6;
      } else {
        return 8;
      }
    });
    setChartData({
      labels: filteredData.map((data) => data.name),
      datasets: [
        {
          label: filteredData.map((data) => data.score),
          data: newaspRatio,
          borderWidth: 1,
          borderRadius: 4,
          backgroundColor: filteredData.map((data) => {
            if (data.mutual === true) {
              return "#11A7D9";
            } else {
              return "#B6D0E2";
            }
          }),
        },
      ],
    });
  };

  const resetData = () => {
    const newaspRatio = topicBreakDownData.map((data) => {
      if (data.score.toLowerCase() === "low") {
        return 4;
      } else if (data.score.toLowerCase() === "medium") {
        return 6;
      } else {
        return 8;
      }
    });

    setChartData({
      labels: topicBreakDownData.map((data) => data.name),
      datasets: [
        {
          label: topicBreakDownData.map((data) => data.score),
          data: newaspRatio,
          borderWidth: 1,
          borderRadius: 4,
          backgroundColor: topicBreakDownData.map((data) => {
            if (data.mutual === true) {
              return "#11A7D9";
            } else {
              return "#B6D0E2";
            }
          }),
        },
      ],
    });
  };

  useEffect(() => {
    setloading(true);
    interactionfetchData().then(() => {
      setloading(false);
    });
  }, [activeKey, id]);

  useEffect(() => {
    if (currentFilter === "true") {
      filterData(true);
    } else if (currentFilter === "false") {
      filterData(false);
    } else {
      resetData();
    }
  }, [currentFilter]);

  const getContainerStyle = () => {
    const length = chartData.labels.length;
    return {
      overflowX: length > 10 ? "auto" : "",
      maxWidth: length > 10 ? "560px" : "100%",
      width: length > 10 ? "580px" : "auto",
      whiteSpace: length > 10 ? "nowrap" : "",
      padding: "10px",
    };
  };

  const getchartWidth = () => {
    const length = chartData.labels.length;

    return length < 10 ? "100%" : `${length * 50}px`;
  };

  const content = <div className="loader" />;
  if (loading) {
    return (
      <Spin tip="Loading..." size="large">
        {content}
      </Spin>
    );
  }
  if (topicBreakDownData.length === 0) {
    return (
      <div className="emptyTopic">
        <Image src={TopicBreakdown} preview={false} />
        <div>
          Lead has not engaged with content on Informa’s platform in the last 12
          months
        </div>
      </div>
    );
  }
  return (
    <>
      <div style={getContainerStyle()}>
        <div
          style={{
            display: "inline-block",
            width: getchartWidth(),
          }}
        >
          <Bar
            data={chartData}
            height={300}
            options={{
              maintainAspectRatio: false,
              CategoryScale: 0,
              scales: {
                Response: true,
                x: {
                  grid: {
                    display: false,
                    borderColor: "#ffffff",
                  },
                  ticks: {
                    align: "center",
                    color: "#000",
                    font: {
                      weight: "600",
                      size: "10px",
                    },
                  },
                },
                y: {
                  beginAtZero: true,
                  ticks: {
                    autoSkip: false,
                    display: false,
                  },
                  max: 8,
                  grid: {
                    display: false,
                    offset: false,
                  },
                  barWidth: 10,
                  display: false,
                },
              },
              plugins: {
                datalabels: {
                  display: false,
                },
                tooltip: {
                  callbacks: {
                    title: () => {
                      return "";
                    },
                    label: (tooltipItem) => {
                      const labelName =
                        tooltipItem.chart.data.labels[tooltipItem.dataIndex];
                      const scoreLabel =
                        tooltipItem.dataset.label[tooltipItem.dataIndex];
                      return [scoreLabel, labelName];
                    },
                  },
                },
                title: {
                  display: false,
                  text: "",
                },
                labels: {
                  render: ({ chart, dataIndex }) => {
                    const dataset = chart.data.datasets[0];
                    const bottomLabel = chart.data.labels[dataIndex];
                    const topLabel = dataset.data[dataIndex];
                    return [bottomLabel, topLabel];
                  },
                  offset: 20,
                  formatter: (labels) => labels.join("\n"),
                },
                legend: {
                  display: false,
                },
              },
            }}
          />
        </div>
      </div>
      <div className="topic-filter">
        <div className="topic-filter-inner-ontainer">
          <span>Labels</span>
          <button
            onClick={() => {
              setCurrentFilter(currentFilter === "true" ? null : "true");
            }}
            className="filter-button1"
          ></button>
          <span className="button-label">Mutual topics of Interest</span>
          <button
            onClick={() => {
              setCurrentFilter(currentFilter === "false" ? null : "false");
            }}
            className="filter-button2"
          ></button>
          <span className="button-label">
            Buyer's unique topics of Interest
          </span>
        </div>
      </div>
    </>
  );
}

export default Topicsbreakdown;
