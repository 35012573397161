export const SET_SELECTED_KEYS = "SET_SELECTED_KEYS";
export const CLEAR_SELECTED_KEYS = "CLEAR_SELECTED_KEYS";
export const SET_CHECK_ALL = "SET_CHECK_ALL";

export const SET_COMPANY_SELECTED_KEYS = "SET_COMPANY_SELECTED_KEYS";
export const CLEAR_COMPANY_SELECTED_KEYS = "CLEAR_COMPANY_SELECTED_KEYS";
export const SET_COMPANY_CHECK_ALL = "SET_COMPANY_CHECK_ALL";

export const SET_PAGINATION = "SET_PAGINATION";
export const SET_COMPANY_PAGINATION = "SET_COMPANY_PAGINATION";

export const setSelecteKeys = (keys) => ({
  type: SET_SELECTED_KEYS,
  payload: keys,
});

export const clearSelectedKeys = () => ({
  type: CLEAR_SELECTED_KEYS,
});

export const setCheckAll = (value) => ({
  type: SET_CHECK_ALL,
  payload: value,
});

export const setSelectedCompany = (keys) => ({
  type: SET_COMPANY_SELECTED_KEYS,
  payload: keys,
});

export const clearSelectedCompanyKeys = () => ({
  type: CLEAR_COMPANY_SELECTED_KEYS,
});

export const setCompanyCheckAll = (value) => ({
  type: SET_COMPANY_CHECK_ALL,
  payload: value,
});

export const setpagination = (current, pagesize) => ({
  type: SET_PAGINATION,
  payload: { current, pagesize },
});

export const setCompanyPagination = (current, pageSize) => ({
  type: SET_COMPANY_PAGINATION,
  payload: { current, pageSize },
});
