import React, { useEffect, useState } from "react";
import "./Registration.style.scss";
import { Button, Checkbox, Form, Input, Typography } from "antd";
import Password from "antd/es/input/Password";
import { useLocation, useNavigate } from "react-router-dom";
import { apiClient, apiClientWithoutAuth } from "../../../api/apiService";
import { validRegex } from "../../../common/constants/regex/regex";
import { StandardModal } from "../../../common/components/Modal/StandardModal";
import { TermsConditions } from "../../../common/components/Modal/TermsConditions";
import { InfoCircleOutlined } from "@ant-design/icons";

const windowWidth = window.innerWidth;
const windowHeight = window.innerHeight;

const { Text } = Typography;

const Registration = () => {
  const location = useLocation();
  const locationInfo = location.search;
  const userInfo = locationInfo.split("&")[0].split("?info=")[1];
  const tokenInfo = locationInfo.split("&")[1].split("reftoken=")[1];
  const decodedValue = Buffer.from(userInfo, "base64").toString("ascii");
  const user = JSON.parse(decodedValue);

  const [formData, setFormData] = useState({
    email: user.email,
    password: "",
    company: user.company,
    confirmPassword: "",
    jobFunction: "",
    username: "",
    consent: false,
  });

  const [errorMessage, setErrormessage] = useState({
    passwordError: "",
    confirmPasswordError: "",
    consentError: "",
    usernameError: "",
  });

  const [showModal, setShowModal] = useState(false);
  const [userExist, setUserExist] = useState(false);
  const [termsModal, setTermsModal] = useState(false);
  const navigate = useNavigate();

  const sessionToken = localStorage.getItem("token");

  if (sessionToken) {
    window.location.href = "/leads/individual";
  }

  const validatePassword = (value) => {
    const errors = { ...errorMessage };
    if (!validRegex.passswordRegex.test(value)) {
      errors.passwordError =
        "Must contain at least 1 number and 1 uppercase and lowercase letter, 1 special character and at least 8 character";
    } else {
      errors.passwordError = "";
    }
    setErrormessage(errors);
  };

  const handleTermsRejection = async () => {
    setTermsModal(!termsModal);
    await apiClientWithoutAuth.post(
      `/send-registration-attempt-email?reftoken=${tokenInfo}`,
      { useremail: formData.email, company: formData.company }
    );
  };

  const validateConfirmPassword = (value) => {
    const errors = { ...errorMessage };
    if (
      formData.confirmPassword &&
      formData.password &&
      formData.confirmPassword !== formData.password
    ) {
      errors.confirmPasswordError = "Password do not match";
    } else {
      errors.confirmPasswordError = "";
    }

    setErrormessage(errors);
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    let newValue = value;

    if (name === "username" || name === "jobFunction") {
      newValue = value.replace(/[^a-zA-Z\s]/g, "");
    }

    if (value === "" || value[0] !== " ") {
      setFormData({
        ...formData,
        [name]: newValue,
      });
    }

    if (name === "password" || name === "confirmPassword") {
      validatePassword(value);
    }

    if (name !== "password") {
      setErrormessage({
        ...errorMessage,
        [`${name}Error`]: "",
      });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const errors = { ...errorMessage };

    if (!formData.password.trim()) {
      errors.passwordError = "Enter valid password";
    } else {
      validatePassword(formData.password);
    }

    if (!formData.confirmPassword.trim()) {
      errors.confirmPasswordError = "Please confirm your password";
    } else if (formData.confirmPassword !== formData.password) {
      errors.confirmPasswordError = "Password do not match";
    }

    if (!formData.consent) {
      errors.consentError = "accept terms & conditions before continuing";
    } else {
      errors.consentError = "";
    }

    setErrormessage(errors);

    if (Object.values(errors).every((val) => val === "")) {
      try {
        const response = await apiClientWithoutAuth.post(
          `/register?info=${userInfo}&reftoken=${tokenInfo}`,
          {
            username: formData.username,
            useremail: formData.email,
            password: formData.password,
            company: formData.company,
            jobfunction: formData.jobFunction,
            consent: formData.consent,
          }
        );
        if (response && response.data.message === "User exist already") {
          setUserExist(true);
          setShowModal(true);
        } else if (response) {
          setShowModal(true);
        }

        setFormData({
          username: "",
          password: "",
          confirmPassword: "",
          company: "",
          jobFunction: "",
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        handleBackToLogin();
      }
    };

    window.addEventListener("keydown", handleEsc);
  }, [navigate]);
  const handleBackToLogin = async () => {
    navigate("/");
    setShowModal(false);
  };

  const handleOnTermsChange = (e) => {
    if (!formData.consent) {
      setErrormessage({
        ...errorMessage,
        consentError: "",
      });

      setTermsModal(true);
    }
  };

  const handleSubmitTerms = () => {
    setFormData({
      ...formData,
      consent: true,
    });
    setTermsModal(!termsModal);
  };

  return (
    <div className="registraton-wrapper">
      <Form layout="vertical" className="registration-container">
        <div className="header">
          <Text className="text">Registration</Text>
        </div>
        <Form.Item label="Email" required={true}>
          <Input
            placeholder="Enter your email"
            type="email"
            name="email"
            value={formData.email}
            readOnly
            disabled
          />
        </Form.Item>
        <Form.Item label="Name" required={true}>
          <Input
            placeholder="Enter your name"
            type="text"
            name="username"
            value={formData.username}
            onChange={handleInputChange}
          />
        </Form.Item>
        <Form.Item label="Password" required={true}>
          <Password
            placeholder="Enter your password"
            name="password"
            value={formData.password}
            onChange={handleInputChange}
          />
          {errorMessage.passwordError && (
            <p className="errorMsg">{errorMessage.passwordError}</p>
          )}
        </Form.Item>
        <Form.Item label="Confirm Password" required={true}>
          <Password
            placeholder="Confirm your password"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleInputChange}
            onBlur={validateConfirmPassword}
          />
          {errorMessage.confirmPasswordError && (
            <p className="errorMsg">{errorMessage.confirmPasswordError}</p>
          )}
        </Form.Item>
        <Form.Item label="Company name" required={true}>
          <Input
            placeholder="Enter your company name"
            type="text"
            name="company"
            value={formData.company}
            readOnly
            disabled
          />
        </Form.Item>
        <Form.Item
          label="Job Function"
          tooltip={{
            title: "Enter job function",
            icon: <InfoCircleOutlined />,
          }}
        >
          <Input
            placeholder="Enter your job function"
            type="text"
            name="jobFunction"
            value={formData.jobFunction}
            onChange={handleInputChange}
          />
        </Form.Item>

        <Form.Item name="checkBox" valuePropName="checked" required={true}>
          <Checkbox checked={formData.consent} onChange={handleOnTermsChange}>
            <p>
              Review
              <Button className="terms-btn" onClick={() => setTermsModal(true)}>
                <p>Terms and Conditions</p>
              </Button>
            </p>
          </Checkbox>
          {errorMessage.consentError && (
            <p className="errorMsg">{errorMessage.consentError}</p>
          )}
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            onClick={handleSubmit}
            disabled={
              !formData.password ||
              !formData.consent ||
              !formData.username ||
              !formData.confirmPassword ||
              errorMessage.passwordError ||
              errorMessage.confirmPasswordError
            }
            className="submit"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>

      <StandardModal
        centered
        showModal={showModal}
        onCancel={handleBackToLogin}
        footer={null}
        message={userExist ? "User already registered " : "Registration successful"}
        onCancelMsg={"Back to login"}
      />

      <StandardModal
        showModal={termsModal}
        closable={false}
        ModalWidth={windowWidth - 200}
        Modalheight={windowHeight}
      >
        <div className="terms-cont">
          <TermsConditions
            onCancelTerms={handleTermsRejection}
            onSubmitTerms={handleSubmitTerms}
          />
        </div>
      </StandardModal>
    </div>
  );
};

export default Registration;
