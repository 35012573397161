import React from "react";
import { useParams } from "react-router-dom";
import { Image } from "antd";
import "./overViewtabs.scss";
import InteractionsOverView from "../interactions";
import infocircle from "../../../../assets/icons/InfoCircle.svg";
function LeadActivityImg() {
  const { id } = useParams();
  return (
    <>
      <div className="interaction-main">
        <div className="titile-container">
          <p className="interaction-title"> Interactions with your company</p>
        </div>
        <div className="intaction-container">
          <InteractionsOverView id={id} />
        </div>
      </div>
      <div className="interaction-error">
        <Image src={infocircle} preview={false} className="error-img" />
        <p>
          This shows the different interactions this lead took with your company
          or products.
        </p>
      </div>
    </>
  );
}

export default LeadActivityImg;
