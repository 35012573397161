import React from "react";
import { formatDateInDetail, handleRateText } from "../utils/helpers";

const RatingHistory = ({ ratingHistoryData }) => {
  return (
    <div className="rating-history-wrapper">
      <div className="rating-title">Ratings history:</div>
      <div className="rating-history-details-wrapper">
        {ratingHistoryData.map((rate, index) => {
          const formattedDate = formatDateInDetail(rate.createdate);

          return (
            <div className="rating-history-details" key={index}>
              <div>
                <p className="rate-date">
                  On{" "}
                  <span className="date">
                    {formattedDate.day}
                    <sup>{formattedDate.daySuffixFormatted}</sup>{" "}
                    {formattedDate.month} {formattedDate.year}
                  </span>
                </p>
                <p className="rated-text-details">
                  You rated this lead to be
                  <span className="rating-text">
                    {" "}
                    {handleRateText(rate.rating).text}{" "}
                  </span>{" "}
                  with the feedback of:
                </p>
                <p className="rate-comment">{rate.comments}</p>
              </div>
              <div className="rate-lead-score-wrapper">
                <p style={{ color: `${handleRateText(rate.rating).color}` }}>
                  {rate.rating} {handleRateText(rate.rating).text}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RatingHistory;
