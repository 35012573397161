import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  ConfigProvider,
  Image,
  Pagination,
  Spin,
  Table,
} from "antd";
import "./CompanyLeads.style.scss";
import styled from "styled-components";
import exportIcon from "../../../assets/icons/exportLeads.svg";
import { StandardModal } from "../../../common/components/Modal/StandardModal";
import { apiClient } from "../../../api/apiService";
import { UniqueModal } from "../../../common/components/Modal/UniqueModal";
import { LeadsFromSameCompany } from "../IndividualLeads/leadsFromSameCompany/LeadsFromSameCompany";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  clearSelectedCompanyKeys,
  setCompanyCheckAll,
  setCompanyPagination,
  setSelectedCompany,
} from "../../../store/action";
import { companyPath } from "../../../common/constants/pagepath/PagePath";
import { apiErrorHandling } from "../../../common/utils/helpers";

const TableContainer = styled.div`
  .ant-table-wrapper table {
    width: 100%;
    padding-bottom: 50px;
  }
  .ant-table-wrapper .ant-table-thead > tr > th {
    text-transform: math-auto;
  }
  .ant-table-wrapper table > tbody > tr {
    padding: 6px;
  }
  .ant-table-tbody {
    cursor: pointer;
  }
  .ant-table-wrapper .ant-table-tbody > tr > td {
    padding: 10px;
  }
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan])::before {
    background-color: white;
  }
  .ant-pagination-item-active {
    background-color: #11a7d9;
    border-radius: 17px;
    color: white;

    a {
      color: white;
    }
  }
`;

const CompanyLeads = ({
  companyFitersData,
  selectedItems1,
  data,
  activeKey,
}) => {
  const [exporting, setExporting] = useState(false);
  const [isCampaign, setIsCampagin] = useState(false);
  const [isFileExported, setIsFileExported] = useState(false);
  const [showFilterCheck, setShowFilterCheck] = useState(false);
  const [showCompanyLeads, setShowCompanyLeads] = useState(false);
  const [loading, setloading] = useState(true);

  const [leadData, setLeadData] = useState({
    companyName: "",
    leadDetails: [],
  });
  const [sortOrder, setSortOrder] = useState("");
  const [sortField, setSortField] = useState("");
  const newSelectedRowKeys = useSelector((state) => state.selectedCompanyRows);
  const isAllSelected = useSelector((state) => state.checkAllCompany);
  const companyPagination = useSelector((state) => state.companypagination);
  const dispatch = useDispatch();

  const toggleCompanyLeadsView = () => {
    setShowCompanyLeads(!showCompanyLeads);
  };

  const fetchCompanyList = async (
    currentPage,
    pagesize,
    sortOrder,
    sortField
  ) => {
    const offset = (currentPage - 1) * pagesize;
    const limit = pagesize;

    try {
      const response = await apiClient.post("/company", {
        offset,
        limit,
        company_name: selectedItems1.searchName,
        company_topic_interest: selectedItems1.topic,
        company_country: selectedItems1.country,
        company_source: selectedItems1.campaign,
        company_nature_of_business: selectedItems1.nature_of_business,
        sortby: sortField,
        type: sortOrder,
      });

      if (selectedItems1.campaign.length !== 0) {
        setIsCampagin(true);
      }
      data.setTotalItems(response.data.totalRecords);
      data.setdatasource(response.data.data);
      if (isAllSelected) {
        const allKeys = response.data.data.map((item) => item.id);
        dispatch(setSelectedCompany([...newSelectedRowKeys, ...allKeys]));
      }
    } catch (error) {
      console.error(error);
      apiErrorHandling(error);
    } finally {
      setloading(false);
    }
  };

  useEffect(() => {
    setloading(true);
    if (activeKey === companyPath)
      fetchCompanyList(
        companyPagination.current,
        companyPagination.pageSize,
        sortOrder,
        sortField
      ).then(() => {
        setloading(false);
      });
  }, [
    companyPagination.current,
    companyPagination.pageSize,
    selectedItems1,
    sortOrder,
    sortField,
  ]);

  const handleOnSelectLeadsView = (leadDetails, companyName) => {
    setLeadData({
      ...leadData,
      companyName: companyName,
      leadDetails: leadDetails,
    });
    toggleCompanyLeadsView();
  };

  const sortHandler = (inputsortField) => {
    if (inputsortField && sortField === inputsortField) {
      const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
      setSortOrder(newSortOrder);
    } else {
      setSortOrder("asc");
      setSortField(inputsortField);
    }
    if (companyPagination.current > 1) {
      dispatch(setCompanyPagination(1, companyPagination.pageSize));
    }
  };

  const onSelectChange = (id) => {
    dispatch(
      setSelectedCompany(
        newSelectedRowKeys.includes(id)
          ? newSelectedRowKeys.filter((selectedKey) => selectedKey !== id)
          : [...newSelectedRowKeys, id]
      )
    );
  };

  const getCurrentPageData = () => {
    const { current, pageSize } = companyPagination;
    const startIndex = (current - 1) * pageSize;
    const enIndex = startIndex + pageSize;
    return data.dataSource.slice(startIndex, enIndex);
  };

  const onSelectAll = (e) => {
    const isChecked = e.target.checked;
    const currentPageDataKeys = getCurrentPageData().map((data) => data.id);
    if (isChecked) {
      dispatch(
        setSelectedCompany([...newSelectedRowKeys, ...currentPageDataKeys])
      );
    } else {
      dispatch(clearSelectedCompanyKeys());
    }
    dispatch(setCompanyCheckAll(isChecked));
  };

  const columns = [
    {
      title: (
        <Checkbox
          onChange={onSelectAll}
          checked={isAllSelected}
          indeterminate={!isAllSelected && newSelectedRowKeys.length > 0}
        />
      ),
      dataIndex: "checkbox",
      key: "checkbox",
      render: (text, record) => (
        <div className="checbox-company">
          <Checkbox
            checked={newSelectedRowKeys.includes(record.id)}
            onChange={(e) => onSelectChange(record.id)}
            onClick={(e) => e.stopPropagation()}
          />
        </div>
      ),
    },
    {
      key: "1",
      title: "Company Name",
      dataIndex: "company_name",
      render: (text, record) => {
        return (
          <div
            onClick={() =>
              handleOnSelectLeadsView(record.leads_details, record.company_name)
            }
          >
            {text}
          </div>
        );
      },
    },
    {
      key: "2",
      title: "Number of leads",
      dataIndex: "leads_details",
      render: (leads_details, company_name) => {
        return (
          <div
            className="lead-name-container"
            onClick={() =>
              handleOnSelectLeadsView(leads_details, company_name.company_name)
            }
          >
            {leads_details.map((item, index) => {
              return (
                <>
                  {index < 4 && (
                    <div
                      key={index}
                      style={{
                        backgroundColor:
                          index === 0
                            ? "#DF5A82"
                            : index === 1
                            ? "#73D4F4"
                            : index === 2
                            ? "#34B4DE"
                            : index === 3
                            ? "#11A7D9"
                            : "transparent",
                        zIndex: 4 + index,
                        marginLeft: index !== 0 ? -10 : 0,
                      }}
                      className="company-list-lead-names"
                    >
                      <p>{item?.name?.charAt(0)}</p>
                    </div>
                  )}
                </>
              );
            })}
            {leads_details.length > 4 && (
              <div className="lead-names">+{leads_details.length - 4}</div>
            )}
          </div>
        );
      },
    },
    {
      key: "3",
      title: (
        <div
          onClick={() => sortHandler("country")}
          className="column-sort-wrapper"
        >
          <div>Country</div>
          <span className="sorter">
            <CaretUpOutlined />
            <CaretDownOutlined />
          </span>
        </div>
      ),
      dataIndex: "country",
      render: (text, record) => {
        return (
          <div
            onClick={() =>
              handleOnSelectLeadsView(record.leads_details, record.company_name)
            }
          >
            {text}
          </div>
        );
      },
    },
    {
      key: "4",
      title: (
        <div
          onClick={() => sortHandler("industry")}
          className="column-sort-wrapper"
        >
          <div>Nature of business</div>
          <span className="sorter">
            <CaretUpOutlined />
            <CaretDownOutlined />
          </span>
        </div>
      ),
      dataIndex: "industry",
      render: (text, record) => {
        return (
          <div
            onClick={() =>
              handleOnSelectLeadsView(record.leads_details, record.company_name)
            }
          >
            {text}
          </div>
        );
      },
    },
    {
      key: "6",
      title: "Topics of interest",
      dataIndex: "topics",

      render: (topics, record) => {
        return (
          <div
            className="topic-container"
            onClick={() =>
              handleOnSelectLeadsView(record.leads_details, record.company_name)
            }
          >
            {topics.length > 2 ? (
              <>
                <div className="topic-item">{topics[0]}</div>
                <div className="topic-item">{topics[1]}</div>
                <div className="topic-item">+{topics.length - 2}</div>
              </>
            ) : (
              topics.map((item, index) => {
                return (
                  <div className="topic-item" key={index}>
                    {item}
                  </div>
                );
              })
            )}
          </div>
        );
      },
    },
  ];

  const handleTableChange = (page, pageSize) => {
    dispatch(setCompanyPagination(page, pageSize));
  };

  const handlePaginationChange = (page, pageSize) => {
    dispatch(setCompanyPagination(page, pageSize));
  };

  const exportSelectedData = async () => {
    try {
      setExporting(true);
      const payload = {
        allRequired: false,
        companyIds: newSelectedRowKeys,
      };

      const payloadForAllSelected = {
        allRequired: true,
        company_name: selectedItems1.searchName,
        company_topic_interest: selectedItems1.topic,
        company_country: selectedItems1.country,
        company_source: selectedItems1.campaign,
        company_nature_of_business: selectedItems1.nature_of_business,
        sortby: sortField,
        type: sortOrder,
      };

      const response = await apiClient.post(
        "/export-companies",
        isAllSelected || newSelectedRowKeys.length === 0
          ? payloadForAllSelected
          : payload
      );

      const downloadFileAtUrl = (url) => {
        fetch(url)
          .then((response) => response.blob())
          .then((blob) => {
            const blobURL = window.URL.createObjectURL(new Blob([blob]));
            const fileName = url.split("/").pop();
            const aTag = document.createElement("a");
            aTag.href = blobURL;
            aTag.setAttribute("download", fileName);
            document.body.appendChild(aTag);
            aTag.click();
            aTag.remove();
          });

        setExporting(false);
        setShowFilterCheck(false);
        setIsFileExported(!isFileExported);
      };

      if (response.data.path) {
        downloadFileAtUrl(response.data.path);
      }
    } catch (error) {
      apiErrorHandling(error);
      console.error(error);
    }
  };
  const handleExportData = async () => {
    if (
      selectedItems1.campaign === "" &&
      selectedItems1.topic === "" &&
      selectedItems1.country === "" &&
      selectedItems1.nature_of_business === "" &&
      (isAllSelected || newSelectedRowKeys.length === 0)
    ) {
      setShowFilterCheck(true);
    } else {
      exportSelectedData();
    }
  };

  const toggleShowFilter = () => {
    setShowFilterCheck(!showFilterCheck);
  };

  const toggleExportCheck = () => {
    setIsFileExported(!isFileExported);
  };

  const content = <div className="loader" />;
  if (loading) {
    return (
      <Spin tip="Loading..." size="large">
        {content}
      </Spin>
    );
  }

  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Table: {
              headerBg: "#ffffff",
            },
          },
        }}
      >
        <div>
          <div className="lead-table-wrapper">
            <h5>Total Companies: {data.totalItems} </h5>
            <Button
              className="export-btn"
              onClick={handleExportData}
              loading={exporting}
            >
              <Image src={exportIcon} preview={false} />
              <div>
                <p>Export Company Leads</p>
              </div>
            </Button>
          </div>

          <TableContainer>
            <Table
              columns={
                !isCampaign || !selectedItems1.campaign
                  ? columns.filter((col) => col.key !== "5")
                  : columns
              }
              rowKey="id"
              dataSource={data.dataSource}
              rowHoverable={true}
              pagination={false}
              onChange={handleTableChange}
            />
            <div className="pagination-wrapper">
              <Pagination
                current={companyPagination.current}
                pageSize={companyPagination.pageSize}
                showSizeChanger={false}
                onChange={handlePaginationChange}
                total={data.totalItems}
              />
            </div>
          </TableContainer>
        </div>
      </ConfigProvider>
      <StandardModal
        showModal={isFileExported}
        onCancel={toggleExportCheck}
        message={"File Exported Successfully"}
        onCancelMsg={"Ok"}
      />
      <UniqueModal
        message={"Export Companies"}
        showModal={showFilterCheck}
        onCancel={toggleShowFilter}
        closable={false}
        ModalWidth={702}
      >
        <div className="export-modal-wrapper">
          <div className="export-modal-text-wrapper">
            <p>
              Companies selected for export are{" "}
              <span>{data.totalItems} companies.</span>
            </p>
            <p>
              You can refine your selection more by applying filters or
              selecting companies.
            </p>
          </div>
          <div className="export-btns-wrapper">
            <Button className="refine-btn" onClick={toggleShowFilter}>
              Refine Export
            </Button>
            <Button
              className="refine-btn export-now-btn"
              onClick={exportSelectedData}
            >
              Export Now
            </Button>
          </div>
        </div>
      </UniqueModal>

      <UniqueModal
        showModal={showCompanyLeads}
        onCancel={toggleCompanyLeadsView}
        closable={false}
        message={"Leads from"}
        boldMessage={leadData.companyName}
      >
        <div
          style={{
            maxHeight: leadData.leadDetails.length > 3 ? "232px" : "auto",
            overflowY: "auto",
          }}
          classname="lead-name-wrapper"
        >
          {leadData.leadDetails.map((item, index) => {
            return (
              <div>
                <LeadsFromSameCompany
                  leadId={item.id}
                  leadName={item.name}
                  jobTitle={item.job_function}
                  lastName={item.lastname}
                />
              </div>
            );
          })}
        </div>
      </UniqueModal>
    </>
  );
};

export default CompanyLeads;
