import {
  SET_SELECTED_KEYS,
  CLEAR_SELECTED_KEYS,
  SET_CHECK_ALL,
  SET_COMPANY_SELECTED_KEYS,
  CLEAR_COMPANY_SELECTED_KEYS,
  SET_COMPANY_CHECK_ALL,
  SET_PAGINATION,
  SET_COMPANY_PAGINATION,
} from "./action";

const initialState = {
  selectedRowKeys: [],
  checkAll: false,
  selectedCompanyRows: [],
  checkAllCompany: false,
  pagination: {
    current: 1,
    pagesize: 10,
  },
  companypagination: {
    current: 1,
    pageSize: 10,
  },
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PAGINATION:
      return {
        ...state,
        pagination: action.payload,
      };
    case SET_COMPANY_PAGINATION:
      return {
        ...state,
        companypagination: action.payload,
      };
    case SET_SELECTED_KEYS:
      return {
        ...state,
        selectedRowKeys: action.payload,
      };
    case CLEAR_SELECTED_KEYS:
      return {
        ...state,
        selectedRowKeys: [],
      };
    case SET_CHECK_ALL:
      return {
        ...state,
        checkAll: action.payload,
      };
    case SET_COMPANY_SELECTED_KEYS:
      return {
        ...state,
        selectedCompanyRows: action.payload,
      };
    case CLEAR_COMPANY_SELECTED_KEYS:
      return {
        ...state,
        selectedCompanyRows: [],
      };
    case SET_COMPANY_CHECK_ALL:
      return {
        ...state,
        checkAllCompany: action.payload,
      };
    default:
      return state;
  }
};

export default rootReducer;
