import React, { useEffect, useState } from "react";
import "./overViewtabs/overViewtabs.scss";
import { Image } from "antd";
import { apiClient } from "../../../api/apiService";
import contentview from "../../../assets/interactionIcons/eye.svg";
import contentdownload from "../../../assets/interactionIcons/download.svg";
import favourite from "../../../assets/interactionIcons/bookmark.svg";
import registerWeb from "../../../assets/interactionIcons/video.svg";
import attendedWeb from "../../../assets/interactionIcons/video.svg";
import badgeScanner from "../../../assets/interactionIcons/Qr cod.svg";
import INBAM from "../../../assets/interactionIcons/IN.svg";
import emailClick from "../../../assets/interactionIcons/mail.svg";
import eventnav from "../../../assets/interactionIcons/IN.svg";
import rfc from "../../../assets/interactionIcons/IN.svg";
import formfill from "../../../assets/interactionIcons/form.svg";
import emailopen from "../../../assets/interactionIcons/new-email-open.svg";
import attsession from "../../../assets/interactionIcons/bookmark.svg";
import exhibitionReg from "../../../assets/interactionIcons/IN.svg";
import exhibitionAtt from "../../../assets/interactionIcons/new-exh-att.svg";
import { apiErrorHandling } from "../../../common/utils/helpers";

const InteractionsOverView = ({ id, activeKey, leadId }) => {
  const [interactionData, setIntergratinData] = useState([]);

  const interactionfetchData = async () => {
    try {
      const response = await apiClient.get(`/lead-activity/interactions/${id}`);
      setIntergratinData(response.data.interactions);
    } catch (error) {
      apiErrorHandling(error);
      console.error(error);
    }
  };

  useEffect(() => {
    interactionfetchData();
  }, [activeKey, id]);

  return (
    <>
      <div className="interactionContainer">
        {interactionData &&
          interactionData.map((ele, index) => {
            return (
              <>
                {ele.label[1] === "Y" && (
                  <div key={index} className="interactionList">
                    <div>
                      <div className="label">
                        {ele.type === "Content View" && (
                          <Image src={contentview} preview={false} />
                        )}
                        {ele.type === "Content Download" && (
                          <Image src={contentdownload} preview={false} />
                        )}
                        {ele.type === "Favourites" && (
                          <Image src={favourite} preview={false} />
                        )}
                        {ele.type === "Registered Webinar " && (
                          <Image src={registerWeb} preview={false} />
                        )}
                        {ele.type === "Registered Webinar" && (
                          <Image src={registerWeb} preview={false} />
                        )}
                        {ele.type === "Attended webinar" && (
                          <Image src={attendedWeb} preview={false} />
                        )}
                        {ele.type === "Ingredients Network RFI" && (
                          <Image src={INBAM} preview={false} />
                        )}
                        {ele.type === "Ingredients Network BAM" && (
                          <Image
                            src={INBAM}
                            preview={false}
                            // style={{ width: "38px" }}
                          />
                        )}
                        {ele.type === "Badge_scan" && (
                          <Image src={badgeScanner} preview={false} />
                        )}
                        {ele.type === "Email Click-through" && (
                          <Image src={emailClick} preview={false} />
                        )}
                        {ele.type === "In-event navigation data" && (
                          <Image src={eventnav} preview={false} />
                        )}
                        {ele.type === "Ingredients Network RFC" && (
                          <Image src={rfc} preview={false} />
                        )}
                        {ele.type === "Email open" && (
                          <Image src={emailopen} preview={false} />
                        )}
                        {ele.type === "Form-Fill" && (
                          <Image src={formfill} preview={false} />
                        )}
                        {ele.type === "Session Registration" && (
                          <Image src={attsession} preview={false} />
                        )}
                        {ele.type === "Session Attendance" && (
                          <Image src={attsession} preview={false} />
                        )}
                        {ele.type === "Exhibition Registration" && (
                          <Image src={exhibitionReg} preview={false} />
                        )}
                        {ele.type === "Exhibition Attendance" && (
                          <Image src={exhibitionAtt} preview={false} />
                        )}
                      </div>
                      {/* <div className="labeltext">{ele.icon_title}</div> */}
                    </div>
                    <div className="interactionData">
                      <h4 className="data date">{ele.date}</h4>
                      <h4 className="data">{ele.label[0]}</h4>
                    </div>
                  </div>
                )}
              </>
            );
          })}
      </div>
    </>
  );
};

export default InteractionsOverView;
