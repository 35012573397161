import React, { useEffect, useState } from "react";
import "./LeadsOverView.style.scss";
import locationIcon from "../../../../assets/icons/CompanyLocation.svg";
import briefcase from "../../../../assets/icons/briefcase.svg";
import company from "../../../../assets/icons/Company.svg";
import { Button, Image, Tooltip } from "antd";
import TextArea from "antd/es/input/TextArea";
import { apiClient } from "../../../../api/apiService";
import {
  formatRelativeTime,
  apiErrorHandling,
} from "../../../../common/utils/helpers";

export const ComapanyOverview = (props) => {
  const { companyName, location, industry, leadId, leadName } = props;
  const [note, setNote] = useState("");
  const [notesHistory, setNotesHistory] = useState([]);

  const fetchNotes = async () => {
    try {
      const response = await apiClient.get(`notes/${leadId}`);
      setNotesHistory(response?.data?.data);
    } catch (err) {
      apiErrorHandling(err);
      console.error(err);
    }
  };

  const addNotes = async () => {
    try {
      await apiClient.post("notes", {
        lead_id: leadId,
        comment: note,
      });
      setNote("");
      await fetchNotes();
    } catch (err) {
      apiErrorHandling(err);
      console.error(err);
    }
  };

  const onNotesChange = (e) => {
    let value = e.target.value;
    if (value === "" || value[0] !== " ") {
      setNote(e.target.value);
    }
  };

  useEffect(() => {
    fetchNotes(leadId);
  }, [leadId]);

  return (
    <>
      <div className="company-overview-wrapper">
        <div className="company-overview">
          <p className="company-text">Company Overview</p>
          <div className="company-overview-items-wrapper">
            <div className="company-overview-item-container">
              <div className="company-overview-left-item">
                <Image src={briefcase} preview={false} />
                <p>Name</p>
              </div>
              <div className="company-overview-right-item">
                <Tooltip title={companyName}>
                  <p>{companyName}</p>
                </Tooltip>
              </div>
            </div>
            {industry === null ? (
              <></>
            ) : (
              <div className="company-overview-item-container">
                <div className="company-overview-left-item">
                  <Image src={company} preview={false} />
                  <p>Industry</p>
                </div>

                <div className="company-overview-right-item">
                  <p className="no-underline">
                    <Tooltip title={industry}>{industry}</Tooltip>
                  </p>
                </div>
              </div>
            )}

            {location === null ? (
              <></>
            ) : (
              <div className="company-overview-item-container">
                <div className="company-overview-left-item">
                  <Image src={locationIcon} preview={false} />
                  <p>Location</p>
                </div>
                <div className="company-overview-right-item">
                  <p className="no-underline">
                    <Tooltip title={location}>{location}</Tooltip>
                  </p>
                </div>
              </div>
            )}
            {/* <div className="company-overview-item-container">
              <div className="company-overview-left-item">
                <Image src={employee} preview={false} />
                <p>Employee size</p>
              </div>
              <div className="company-overview-right-item">
                <p className="no-underline">{employeeSize?employeeSize:"Unavailable"}</p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div className="notes-wrapper">
        <div className="notes-title">Notes section</div>
        {notesHistory?.length !== 0 && (
          <div className="notes-history-wrapper">
            {notesHistory.map((note, index) => {
              const relativeTime = formatRelativeTime(note.createdate);
              return (
                <div key={index} className="notes-section">
                  <div className="lead-literal lead-user-literal">
                    <p>{note?.username?.charAt(0).toUpperCase()}</p>
                  </div>
                  <div>
                    <p className="lead-username">
                      {note?.username}{" "}
                      <span className="formatteddate">{relativeTime}</span>
                    </p>
                    <div className="note-container">
                      <p className="lead-username note">{note?.comments}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}

        <div className="notes-section">
          <div className="lead-literal">
            <p>{leadName?.charAt(0).toUpperCase()}</p>
          </div>
          <div className="notes-textarea-wrapper">
            <div className="notes-textarea">
              <TextArea
                className="textarea"
                rows={9 - (notesHistory?.length + 1)}
                placeholder={`Add your note`}
                name="note"
                value={note}
                onChange={onNotesChange}
              />
            </div>
            <div className="add-notes-btn">
              <Button
                className={note ? "add-btn add-btn-enabled" : "add-btn"}
                disabled={!note}
                onClick={addNotes}
              >
                Add Note
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
