import React, { useEffect, useState } from "react";
import { Button } from "antd";
import "./UserProfile.style.scss";
import { apiClient } from "../../api/apiService";
import { apiErrorHandling } from "../../common/utils/helpers";

function UserProfile({ onCancelButton }) {
  const [profileData, setProfileData] = useState([]);
  const Email = localStorage.getItem("email");
  const commonNameColors = ["#11A7D9", "#73D4F4", "rgba(223, 89, 130, 0.80)"];

  const profileFetchData = async () => {
    try {
      const response = await apiClient.get(`/userprofile?email=${Email}`);
      setProfileData(response.data.data);
    } catch (error) {
      apiErrorHandling(error);
      console.error(error, "error");
    }
  };

  useEffect(() => {
    profileFetchData();
  }, []);

  const getRandomColor = () => {
    return commonNameColors[
      Math.floor(Math.random() * commonNameColors.length)
    ];
  };

  return (
    <div>
      <div className="user-profile-container">
        <div className="profile-row">
          <div
            className="profile-row-first-char"
            style={{ backgroundColor: getRandomColor() }}
          >
            <p>{profileData.name?.charAt(0).toUpperCase()}</p>
          </div>
          <div className="profile-detail">
            <div className="profile-detail-row">
              <p className="profile-label">Name</p>
              <p className="profile-value">{profileData.name}</p>
            </div>
            <div className="profile-detail-row">
              <p className="profile-label">Email</p>
              <p className="profile-value">{profileData.email}</p>
            </div>
            <div className="profile-detail-row">
              <p className="profile-label">Company</p>
              <p className="profile-value">{profileData.company}</p>
            </div>
            {profileData.jobTitle !== "" && (
              <div className="profile-detail-row">
                <p className="profile-label">Job Function</p>
                <p className="profile-value">{profileData.jobTitle}</p>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="profile-button-container">
        <Button
          htmlType="submit"
          className="cancel-button"
          onClick={onCancelButton}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
}

export default UserProfile;
