import React, { useRef, useState } from "react";
import "./Login.style.scss";
import { useNavigate } from "react-router-dom";
import { Space, Input, Button, notification } from "antd";
import Password from "antd/es/input/Password";
import { apiClientWithoutAuth } from "../../../api/apiService";
import { validRegex } from "../../../common/constants/regex/regex";
import { leadPath } from "../../../common/constants/pagepath/PagePath";

function Login() {
  const [api, contextHolder] = notification.useNotification();
  const [user, setUser] = useState({ email: "", password: "" });
  const [errorMessage, setErrorMessage] = useState({
    email: "",
    login: "",
    password: "",
  });
  const [showEmailError, setShowEmailError] = useState(false);
  const [showpswdError, setShowPswdError] = useState(false);
  const [isLoginDisabled, setisLoginDisabled] = useState(false);

  const passWordref = useRef();

  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  if (token) {
    window.location.href = "/leads/individual";
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value.trim() });
  };

  const validateEmail = () => {
    if (
      user.email &&
      (!user.email.trim() || !validRegex.emailRegex.test(user.email))
    ) {
      setErrorMessage({
        ...errorMessage,
        email: "Enter a valid email address",
      });
      setShowEmailError(true);
      setShowPswdError(true);
    } else {
      // if (
      //   user.email &&
      //   user.email.trim().split("@")[1].split(".")[0] !== "ltimindtree" &&
      //   user.email.trim().split("@")[1].split(".")[0] !== "informa" &&
      //   user.email.trim().split("@")[1].split(".")[0] !== "gmail"
      // ) {
      //   setErrorMessage({
      //     ...errorMessage,
      //     email: "Domain Should be ltimindtree.",
      //   });
      //   setShowEmailError(true);
      // } else {
      setErrorMessage({
        ...errorMessage,
        email: "",
      });
      setShowEmailError(false);
      setShowPswdError(false);
      // }
    }
  };

  const handleOk = () => {
    api.destroy();
    passWordref.current.focus();
    setUser({ ...user, email: "", password: "" });
    setisLoginDisabled(false);
  };

  const openNotification = (placement, message) => {
    setisLoginDisabled(true);
    const btn = (
      <Space>
        <Button type="text" size="small" onClick={handleOk}>
          Ok
        </Button>
      </Space>
    );
    api.error({
      message: "",
      description: message,
      placement,
      closeIcon: false,
      duration: 0,
      btn,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!user.email.trim() || !validRegex.emailRegex.test(user.email)) {
      setErrorMessage({
        ...errorMessage,
        email: "Enter a valid email address",
      });
      setShowEmailError(true);
      setUser({ ...user, email: "", password: "" });
      return;
    }
    try {
      const signupResponse = await apiClientWithoutAuth.post("/signin", user);

      if (signupResponse.status === 200) {
        const { token, admin } = signupResponse.data;

        localStorage.setItem("token", token);
        localStorage.setItem("email", user.email);
        localStorage.setItem("admin", admin);
        navigate(leadPath);
      }
    } catch (error) {
      if (
        error.response.status === 401 &&
        error.response.data.message === "Invalid credentials"
      ) {
        openNotification(
          "top",
          "Email or Password is incorrect, please try again."
        );
      } else if (
        error.response.status === 401 &&
        error.response.data.message === "Incorrect user email & password"
      ) {
        openNotification(
          "top",
          "Email or Password is incorrect, please try again."
        );
      } else {
        openNotification(
          "top",
          <span>
            We couldn't log you in. Need assistance, feel free to reach out to
            our support team at{" "}
            <a
              style={{
                color: "blue",
                cursor: "pointer",
                textDecoration: "underline",
              }}
              href="mailto:Liban.ibrahim@informa.com"
            >
              Liban.ibrahim@informa.com
            </a>
          </span>
        );
      }
    }
  };

  const isSubmitDisabled =
    user.email === "" || user.password === "" || isLoginDisabled;

  return (
    <>
      {contextHolder}
      <form className="form" onSubmit={handleSubmit}>
        <div className="login-container">
          <h1 className="title">Log in to your Account</h1>
          <div>
            <div className="label-container">
              <label className="lable">Email</label>
              <div>
                <Input
                  placeholder="Enter your email"
                  type="text"
                  value={user.email}
                  name="email"
                  onChange={handleChange}
                  className="input"
                  onBlur={validateEmail}
                  ref={passWordref}
                />
                {showEmailError && (
                  <p className="errorMsg">{errorMessage.email}</p>
                )}
              </div>

              <div style={{ marginTop: "6px" }}>
                <label className="lable">Password</label>
                <div>
                  <Password
                    placeholder="Enter your password"
                    name="password"
                    value={user.password}
                    onChange={handleChange}
                    className="input"
                  />
                  {showpswdError && (
                    <p className="errorMsg">{errorMessage.password}</p>
                  )}
                </div>
              </div>
            </div>

            <Button
              block
              className={`button ${isSubmitDisabled ? "button-disabled" : ""}`}
              disabled={isSubmitDisabled}
              type="primary"
              htmlType="submit"
            >
              Login
            </Button>
          </div>
        </div>
      </form>
    </>
  );
}

export default Login;
