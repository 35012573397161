import { Button } from "antd";
import React, { useRef } from "react";
import "./Modal.style.scss";
import { CloseOutlined } from "@ant-design/icons";

export const TermsConditions = (props) => {
  const { onSubmitTerms, onCancelTerms } = props;
  const termsContentRef = useRef(null);

  const handleBack = () => {
    onCancelTerms();
    if (termsContentRef.current) {
      termsContentRef.current.scrollTop = 0;
    }
  };

  return (
    <>
      <div className="terms-condition-container">
        <h4>Terms and conditions of InTouch platform use</h4>
        <div onClick={handleBack} className="close-terms">
          <CloseOutlined />
        </div>
      </div>
      <div className="terms-condition-wrapper" ref={termsContentRef}>
        <p>
          These terms and conditions (together with any other documents referred
          to in it) (Terms), together with the booking contract you signed in
          respect of your sponsor/exhibitor/digital package (Booking Contract)
          and any applicable data protection addendum tells you the terms of use
          on which you may make use of this platform (our Site). In the event of
          any conflict between these Terms and the Booking Contract in respect
          of the Site, these Terms shall prevail but only to the extent of such
          conflict.
        </p>
        <p>
          Please read these Terms carefully before you start to use our Site. By
          using our Site, you indicate that you accept these Terms and that you
          agree to abide by them. If you do not agree to these Terms, please
          refrain from using our Site immediately.
        </p>
        <p>
          We may revise these Terms at any time by amending this page. You are
          expected to check this page from time to time to take notice of any
          changes we make, as they are binding on you. Some of the provisions
          contained in these Terms may also be superseded by provisions or
          notices published elsewhere on our Site.
        </p>
        <h4>Information about us</h4>
        <p>
          This Site is operated by Informa Markets B.V. (we, us or our), which
          is a part of the Informa group of companies. Our registered office
          address is WTC, Tower Ten, 7th floor, Strawinskylaan 763, 1077XX
          Amsterdam, The Netherlands.
        </p>

        <h4>Information about you</h4>
        <p>
          For the purpose of these Terms, you and your means the customer,
          client, exhibitor or sponsor entity named in the Booking Contract. The
          individual accepting these Terms on behalf of such customer, client,
          exhibitor or sponsor confirms that it is duly authorised and possesses
          all necessary power and authority to provide such acceptance of these
          Terms.
        </p>
        <h4>Accessing our Site</h4>
        <p>
          Access and use of the Site is provided in accordance with the Booking
          Contract and these Terms. Access is provided on a temporary basis and
          we reserve the right to withdraw access to our Site. You are
          responsible for making all arrangements necessary for you to have
          access to our Site.
        </p>
        <p>
          You are also responsible for ensuring that all persons who access our
          Site through your internet connection are aware of these Terms, and
          that they comply with them. You agree not to damage, interfere with or
          disrupt access to the Site or do anything which may interrupt or
          impair its functionality.
        </p>
        <p>
          We may make changes to or amend the service we provide on our Site
          without notice. This includes change, adding or removing content,
          features and/or functionality. We will not be liable if for any reason
          our Site is unavailable at any time or for any period.
        </p>
        <p>
          Anything on our Site may be out of date at any given time, and we are
          under no obligation to update it. We seek to ensure that information
          published on our Site is accurate when posted, but we cannot be held
          liable for its accuracy or timeliness and we may change the
          information at any time without notice. You must not rely on
          information on the Site and you acknowledge that you must take
          appropriate steps to verify this information before acting upon it.
        </p>
        <h4>Limitation on access</h4>
        <p>
          Your access to the Site is personal to you and non-transferable.
          Access to the Site shall only be available to you and may not be
          shared with other persons, either internally or externally, except
          where expressly agreed with us. You shall comply with all laws,
          regulations and sanctions applicable to your access to and use of the
          Site. We reserve the right to monitor your use of the Site to ensure
          compliance with this requirement.
        </p>
        <h4>Passwords</h4>
        <p>
          You agree to assume sole responsibility for the security of any
          password(s) issued to you to access the Site. Such passwords are
          subject to cancellation or suspension with notice at any time if we
          reasonably believe that you have breached these Terms.
        </p>
        <h4>Monitoring</h4>
        <p>
          We reserve the right to monitor and track your visits to the Site.{" "}
        </p>
        <h4>Communications with other users</h4>
        <p>
          The Site may provide facilities that enable you to communicate with
          other users of the Site. If you communicate with any other user of the
          Site, you must ensure that: <br />• to the best of your knowledge and
          belief, any such communication is truthful and accurate in all
          respects;
          <br />• no such communication shall be inappropriate, abusive or
          objectionable; and <br />• if applicable, you shall immediately comply
          with any request from any other user of the Site to stop communicating
          with them.
        </p>
        <h4>User Content</h4>
        <p>
          If we receive any information or materials from you (including,
          without limitation, by email) via the Site{" "}
          <span style={{ fontWeight: "700" }}>(User Content)</span>, we confirm
          such User Content shall not be displayed or made available publicly or
          to other users of the Site and shall only be used to provide you with
          features of the Site and to develop Site.
        </p>
        <p>
          You acknowledge we may provide User Content to our agents and
          contractors to enable us to provide and/or develop the Site.
        </p>
        <p>
          You agree that any intellectual property developed or derived by us
          from your User Content shall be owned by us and you waive any moral
          rights in such intellectual property.
        </p>
        <p>
          You hereby grant to us a perpetual, irrevocable, fully paid-up,
          worldwide, royalty-free, non-exclusive, sub-licensable right and
          license to use, reproduce, modify, adapt and edit such User Content
          for any commercial and non-commercial purposes related to our
          business. If you do not wish to grant such rights, then please do not
          submit any User Content to us.
        </p>
        <p>
          By submitting any User Content to us, you warrant that such User
          Content is your own original work and that you are fully entitled to
          make it available to us for all the purposes specified above and agree
          to waive any moral rights in the User Content. Under no circumstances
          should you submit any User Content that:
        </p>
        <p>
          • is inaccurate, misleading, defamatory, illegal, abusive, vulgar,
          obscene, inflammatory, of a discriminatory nature, in breach of
          confidence or privacy or which is otherwise unlawful, offensive or
          which might reasonably cause offence to any person or damage our
          reputation. You must not use any abusive or aggressive language,
          swear, threaten, harass or abuse any other person, including other
          users of the Site;
          <br />• you do not own or for which you have not obtained all
          necessary licenses and approvals;
          <br /> • is technically harmful including, without limitation,
          containing computer viruses, Trojan horses, worms, corrupted data,
          malicious software or harmful data; <br />• misrepresents the user or
          impersonates another person or entity or falsely states or otherwise
          misrepresents your affiliation with any person or entity.
        </p>
        <p>
          Any User Content submitted to us is submitted on the basis that we may
          (but shall be under no obligation to) review and edit such User
          Content. We reserve the right to remove any User Content or part
          thereof published on the Site at any time and for any reason at our
          absolute discretion without notification to you.
        </p>
        <p>
          We do not endorse or accept any responsibility for the use of, or any
          content within, the Site, any User Content or any content that
          features within any website linked from or referenced on the Site. We
          will not be liable for any loss, damage, cost, claim or expense
          suffered or incurred by you and/or any of your personnel arising out
          of or in connection with the use of, or reliance on, any content,
          products and/or services available on or through the Site or any of
          our other websites.
        </p>

        <p>
          You agree to indemnify, defend and hold harmless us and our directors,
          officers, employees and other representatives from and against all
          liabilities, claims, demands, expenses and costs arising out of your
          submission of User Content that breaches these Terms.
        </p>
        <h4>Intellectual property</h4>
        <p>
          We are the owner or the licensee of all copyright, trade marks, design
          rights, database rights, confidential information or any other
          intellectual property rights (together the Intellectual Property) in
          our Site and any improvement we make to the Site based on your use of
          the Site or any User Content. The Intellectual Property in our Site
          and any improvement we make to the Site based on your use of the Site
          or any User Content is protected by copyright and other intellectual
          property laws and treaties around the world. All such rights are
          reserved.
        </p>
        <h4>Licence</h4>
        <p>
          You are permitted to print and download extracts from the Site for
          your own use on the following basis: <br />
          (a) no documents or related graphics on the Site are modified in any
          way; <br />
          (b) no graphics on the Site are used separately from the corresponding
          text; and <br />
          (c) our copyright and trade mark notices and this permission notice
          appear in all copies.
        </p>
        <p>
          Unless otherwise stated, the copyright and other intellectual property
          rights in all material on the Site (including without limitation
          photographs and graphical images) are owned by us or our licensors.
          For the purposes of these Terms, any use of extracts from the Site
          other than in accordance with this licence for any purpose is
          prohibited. If you breach any of the terms in this legal notice, your
          permission to use the Site automatically terminates and you must
          immediately destroy any downloaded or printed extracts from the Site
        </p>
        <p>
          Subject to this licence, no part of the Site may be reproduced or
          stored in any other website or included in any public or private
          electronic retrieval system or service without our prior written
          permission. Any rights not expressly granted in these terms are
          reserved. You agree not to adapt, alter or create a derivative work
          from any of the material contained in this Site or use it for any
          other purpose than for personal, non-commercial use.
        </p>
        <h4>Misuse of the Site</h4>
        <p>
          You must not alter, add to, delete, remove or tamper with the Site or
          any part of it or indirectly disrupt or interfere (or attempt to
          disrupt or interfere) with or alter the Site. You must not misuse the
          Site by knowingly introducing viruses, Trojan horses, worms, logic
          bombs or other material which is malicious or technologically harmful.
          You must not attempt to gain unauthorised access to the Site, the
          server on which the Site is stored or any server, computer or database
          connected to the Site. You must not attack the Site via a
          denial-of-service attack or a distributed denial-of service attack.
        </p>
        <p>
          You must not access the Site for unlawful use, including, without
          limitation, use which would constitute a criminal offence. You hereby
          agree to on demand indemnify and keep us indemnified for any costs,
          claims, liabilities, fines, expenses, demands, proceedings and losses
          whatsoever made against or incurred by us as a direct or indirect
          result of your breach of this paragraph.
        </p>
        <p>
          We will not be liable for any loss, disruption or damage caused by a
          distributed denial-of-service attack, viruses or other technologically
          harmful material that may infect your computer equipment, computer
          programs, data or other proprietary material due to your use of the
          Site or to your downloading of any material posted on it or on any
          website linked to it. We recommend that you take measures to protect
          the security of your computer, including, without limitation,
          installing anti-virus software on your computer.
        </p>
        <h4>Links to the Site</h4>
        <p>
          Where we authorise you to link to the Site, you may include a link to
          the Site provided you do so in a way that is fair and legal and does
          not damage our reputation or take advantage of it. You must not
          establish a link in such a way as to suggest any form of association,
          approval or endorsement on our part where none exists. You must not
          establish a link to the Site in any website that is not owned by you.
          We may withdraw our authorisation for you to link to the Site at any
          time without notice.
        </p>
        <h4>Disclaimer</h4>
        <p>
          While we endeavour to ensure that the information on the Site is
          correct, we do not warrant the accuracy and completeness of the
          material on the Site. We may make changes to the material on the Site,
          at any time without notice. The material on the Site may be out of
          date, and we make no commitment to update such material.
        </p>
        <h4>Implied Terms</h4>
        <p>
          The express provisions of these Terms are in place of all warranties,
          conditions, terms, undertakings and obligations implied by statute,
          common law, trade usage, course of dealing or otherwise, all of which
          are excluded to the fullest extent permitted by law.
        </p>
        <h4>Liability</h4>
        <p>
          Except in respect of the Booking Contract, the material on the Site is
          provided "as is", without any conditions, warranties or other terms of
          any kind. We make no warranty as to any outcome or possible outcome
          from using the Site.
        </p>
        <p>
          We, any other party (whether or not involved in creating, producing,
          maintaining or delivering the Site), and any of our group companies
          and the officers, directors, employees, shareholders or agents of any
          of them, exclude all liability and responsibility for any amount or
          kind of loss or damage that may result to you or a third party
          (including without limitation, any direct, indirect, punitive or
          consequential loss or damages, or any loss of income, profits,
          goodwill, data, contracts, use of money, or loss or damages arising
          from or connected in any way to business interruption, and whether in
          tort (including without limitation negligence), contract or otherwise)
          in connection with the Site in any way or in connection with the use,
          inability to use or the results of use of the Site, any websites
          linked to the Site or the material on such websites, including but not
          limited to loss or damage due to viruses that may infect your computer
          equipment, software, data or other property on account of your access
          to, use of, or browsing the Site or your downloading of any material
          from the Site or any websites linked to the Site.
        </p>
        <p>
          Nothing in these Terms shall exclude or limit our liability for death
          or personal injury caused by negligence (as such term is defined by
          the Unfair Contract Terms Act 1977); or fraud.
        </p>
        <h4>Jurisdiction and applicable law</h4>
        <p>
          The English courts will have non-exclusive jurisdiction over any claim
          arising from, or related to, a visit to our Site, although we retain
          the right to bring proceedings against you for breach of these Terms
          in your country of residence or any other relevant country.
        </p>
        <p>
          These Terms and any dispute or claim arising out of or in connection
          with them or their subject matter or formation (including, without
          limitation, non-contractual disputes or claims) shall be governed by
          and construed in accordance with the laws of England and Wales.
        </p>
        <p>Thank you for visiting our Site.</p>
        <div className="terms-condition">
          <Button className="terms-accept" onClick={onSubmitTerms}>
            <p> I Accept</p>
          </Button>
        </div>
      </div>
    </>
  );
};
