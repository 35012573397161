// Helpers
import moment from "moment";
import "moment-timezone";

export const capitalizeFirstLetter = (text) => {
  return text?.charAt(0).toUpperCase() + text?.slice(1);
};

export const handleRateText = (rate) => {
  let color = "";
  let text = "";
  switch (rate) {
    case 1:
      color = "#DF5982";
      text = "Strongly Irrelevant";
      break;
    case 2:
      color = "#DF5982CC";
      text = "Irrelevant";
      break;
    case 3:
      color = "#022343";
      text = "Neutral";
      break;
    case 4:
      color = "#73D4F4";
      text = "Relevant";
      break;
    case 5:
      color = "#11A7D9";
      text = "Strongly Relevant";
      break;
    default:
      color = "#11A7D9";
      text = "unknown";
  }

  return {
    color: color,
    text,
  };
};

export const formatDateInDetail = (inputDate) => {
  const date = new Date(inputDate);
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "long" });
  const year = date.getFullYear();

  const daySuffix = (day) => {
    if (day > 3 && day < 21) return "th";
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  const daySuffixFormatted = daySuffix(day);

  return { day, daySuffixFormatted, month, year };
};

export const formatRelativeTime = (inputDate) => {
  const localDate = moment(inputDate).local();
  return localDate.fromNow();
};

export const resetToken = () => {
  localStorage.removeItem("token");
  window.location.href = "/";
};

export const apiErrorHandling = (error) => {
  if (
    error.response.status === 401 &&
    error.response.data.message === "Invalid token"
  ) {
    resetToken();
  }
};
