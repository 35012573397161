import React, { useState } from "react";
import { Tabs } from "antd";
import IndividualLeads from "../../../Leads/IndividualLeads/IndividualLeads";
import CompanyLeads from "../../../Leads/CompanyLeads/CompanyLeads";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import {
  companyPath,
  leadPath,
} from "../../../../common/constants/pagepath/PagePath";

const { TabPane } = Tabs;
const TabContainerOverView = styled.div`
  .ant-tabs-top > .ant-tabs-nav {
    border-bottom: 1px solid #0000000f;
  }

  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
    align-items: flex-start !important;
    justify-content: flex-start !important;
  }
  .ant-tabs-tab .ant-tabs-tab-active {
    border-bottom: 1px solid black;
  }
  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active {
    border-bottom: 1px solid black !important;
  }
  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: black !important;
  }
`;
function LeadsContainer({ subTabKey, selectedItems }) {
  const [dataSource, setdatasource] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [IndividualdataSource, setIndivdualdatasource] = useState([]);
  const [individualtotalItems, setIndividualTotalItems] = useState(0);

  const [individualFitersData, setindividualsFilterData] = useState({
    jobtitle: "",
    topic: "",
    source: "",
    nature_of_business: "",
  });
  const navigate = useNavigate();

  const [companyFitersData, setCompanyFilterData] = useState({
    topic: "",
    source: "",
    nature_of_business: "",
  });

  const handleTabChange = (key) => {
    if (key === leadPath) {
      setTotalItems(0);
      setdatasource([]);
    } else if (key === companyPath) {
      setIndividualTotalItems(0);
      setIndivdualdatasource([]);
    }
    navigate(key);
  };

  return (
    <div>
      <p className="lead-title">Leads</p>
      <div>
        <TabContainerOverView>
          <Tabs activeKey={subTabKey} onChange={handleTabChange}>
            <TabPane
              tab={
                <Link to="/leads/individual">
                  <p className="tab-title">Individuals</p>
                </Link>
              }
              key="/leads/individual"
            >
              <IndividualLeads
                individualFitersData={individualFitersData}
                selectedItems1={selectedItems}
                setindividualsFilterData={setindividualsFilterData}
                data={{
                  IndividualdataSource,
                  setIndivdualdatasource,
                  individualtotalItems,
                  setIndividualTotalItems,
                }}
                activeKey={subTabKey}
              />
            </TabPane>
            <TabPane
              tab={
                <Link to="/leads/company">
                  <p className="tab-title">Company</p>
                </Link>
              }
              key="/leads/company"
            >
              <CompanyLeads
                companyFitersData={companyFitersData}
                selectedItems1={selectedItems}
                setCompanyFilterData={setCompanyFilterData}
                data={{ dataSource, setdatasource, totalItems, setTotalItems }}
                activeKey={subTabKey}
              />
            </TabPane>
          </Tabs>
        </TabContainerOverView>
      </div>
    </div>
  );
}

export default LeadsContainer;
