import React from "react";
import "./Modal.style.scss";
import { Button, Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";

export const UniqueModal = (props) => {
  const {
    message,
    boldMessage,
    onCancel,
    showModal,
    centered,
    closable,
    ModalWidth,
    title,
    children,
    ModelHeight,
  } = props;
  return (
    <Modal
      title={title}
      open={showModal}
      footer={null}
      onCancel={onCancel}
      centered={centered}
      closable={closable}
      width={ModalWidth}
      height={ModelHeight}
    >
      <div className="rate-lead-title">
        <p>
          {message} <span>{boldMessage ? boldMessage : ""}</span>
        </p>
        <Button onClick={onCancel} className="close-rate">
          <CloseOutlined
            style={{ width: "24px", height: "24px", color: "#000" }}
          />
        </Button>
      </div>
      {children}
    </Modal>
  );
};
