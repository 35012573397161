import React, { useEffect, useState } from "react";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Bar } from "react-chartjs-2";
import { apiClient } from "../../../api/apiService";
import { Image, Spin } from "antd";
import infocircle from "../../../assets/icons/InfoCircle.svg";
import "./IndividualLeads.style.scss";
import TopicBreakdown from "../../../assets/icons/topicBreakdown.svg";
import Light from "../../../assets/icons/Light.png";
import Ellipse from "../../../assets/icons/Ellipse.svg";
import { apiErrorHandling } from "../../../common/utils/helpers";

Chart.register(CategoryScale, ChartDataLabels);

function ContentConsumption({ id, activeKey }) {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "",
        data: [],
        backgroundColor: [],
        borderWidth: 1,
      },
    ],
  });
  const [loading, setloading] = useState(false);

  const contentfetchData = async () => {
    try {
      const response = await apiClient.get(
        `/lead-activity/content-consumption/${id}`
      );
      const rawData = response.data.content.map((data) => data.score);
      const percentageData = rawData.map((score) => score * 100);
      setChartData({
        labels: response.data.content.map((data) => {
          if (data.contenttype === "Company") {
            return "Company page";
          }
          return data.contenttype;
        }),
        datasets: [
          {
            label: "",
            data: percentageData,
            backgroundColor: "#FFD275",
            borderWidth: 1,
            borderRadius: 4,
            color: "black",
          },
        ],
      });
    } catch (error) {
      apiErrorHandling(error);
      console.error(error);
    } finally {
      setloading(false);
    }
  };

  useEffect(() => {
    setloading(true);
    contentfetchData().then(() => {
      setloading(false);
    });
  }, [activeKey, id]);

  const getContainerStyle = () => {
    const length = chartData.labels.length;
    return {
      overflowX: length > 10 ? "auto" : "",
      maxWidth: "100%",
      width: length > 10 ? "280px" : "70%",
      whiteSpace: length > 10 ? "nowrap" : "",
      padding: "10px",
      display: "flex",
    };
  };

  const getchartWidth = () => {
    const length = chartData.labels.length;
    return length < 10 ? "100%" : `${length * 50}px`;
  };

  const content = <div className="loader" />;
  if (loading) {
    return (
      <Spin tip="Loading..." size="large">
        {content}
      </Spin>
    );
  }

  if (chartData.labels.length === 0) {
    return (
      <div className="emptyTopic">
        <Image src={TopicBreakdown} preview={false} />
        <div>
          Lead has not engaged with content on Informa’s platform in the last 12
          months
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="content-main-container">
        <div style={getContainerStyle()}>
          <div style={{ display: "inline-block", width: getchartWidth() }}>
            <Bar
              data={chartData}
              height={300}
              options={{
                maintainAspectRatio: false,
                CategoryScale: 0,
                layout: {
                  padding: {
                    top: 10,
                  },
                },
                scales: {
                  x: {
                    grid: {
                      display: false,
                      borderColor: "#ffffff",
                    },
                    ticks: {
                      color: "#000",
                      font: {
                        weight: "600",
                        size: "10px",
                      },
                    },
                  },
                  y: {
                    grid: {
                      display: false,
                      offset: false,
                    },
                    ticks: {
                      display: false,
                    },
                    display: false,
                  },
                },
                plugins: {
                  datalabels: {
                    anchor: "end",
                    align: "end",
                    offset: -5,
                    formatter: (value) => value.toFixed(2) + "%",
                    color: "#000",
                    font: {
                      weight: "bold",
                      size: "10",
                    },
                  },
                  tooltip: {
                    enabled: false,
                  },
                  title: {
                    display: false,
                    text: "",
                    color: "#000000",
                  },
                  labels: {
                    render: ({ chart, dataIndex }) => {
                      const dataset = chart.data.datasets[0];
                      const bottomLabel = chart.data.labels[dataIndex];
                      const topLabel = dataset.data[dataIndex];
                      return [bottomLabel, topLabel];
                    },
                    offset: 20,
                    formatter: (labels) => labels.join("\n"),
                  },
                  legend: {
                    display: false,
                  },
                },
              }}
            />
          </div>
        </div>
        <div className="content-info">
          <div className="light-container">
            <Image src={Light} preview={false} />
          </div>
          <div className="info-container">
            <p>
              <b>Company page and Product</b> content consumption includes
              tracking various interactions such as
            </p>
          </div>
          <div className="list-container">
            <div>
              <Image src={Ellipse} preview={false} />
              <span>Page view</span>
            </div>
            <div>
              <Image src={Ellipse} preview={false} />
              <span>Bookmark</span>
            </div>
            <div className="inner-list-container">
              <div>
                <Image src={Ellipse} preview={false} />
              </div>
              <div className="inner-text">
                <span>Request through Ingredients Network</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="error-info">
        <Image src={infocircle} preview={false} className="error-img" />
        <p>This shows the lead’s content type consumption.</p>
      </div>
    </>
  );
}

export default ContentConsumption;
