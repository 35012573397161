import axios from "axios";
const baseURL = process.env.REACT_APP_BASE_URL;

const apiClientWithoutAuth = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

const apiClient = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

apiClient.interceptors.request.use(
  (request) => {
    const token = localStorage.getItem("token");
    if (token) {
      request.headers.Authorization = token;
      return request;
    } else {
      window.location.href = "/";
      throw new Error("no token");
    }
  },
  (error) => Promise.reject(error)
);

export { apiClient, apiClientWithoutAuth };
