import { LeftOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { Dropdown, Tabs, Tooltip } from "antd";
import styled from "styled-components";
import "./LeadsOverView.style.scss";
import "../../CompanyLeads/CompanyLeads.style.scss";
import profile from "../../../../assets/Profile/image.png";
import { Button, Image } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import emailIcon from "../../../../assets/icons/email.jpg";
import phone from "../../../../assets/icons/phoneCall.svg";
import star from "../../../../assets/icons/star.svg";
import { ComapanyOverview } from "./ComapanyOverview";
import { LeadActivity } from "../LeadActivity";
import Topicsbreakdown from "../Topicsbreakdown";
import ContentConsumption from "../ContentConsumption";
import { RateLeadModal } from "../../../../common/components/Modal/RateLeadModal";
import { apiClient } from "../../../../api/apiService";
import { LeadsFromSameCompany } from "../leadsFromSameCompany/LeadsFromSameCompany";
import { UniqueModal } from "../../../../common/components/Modal/UniqueModal";
import { InviteTeamMembers } from "../../../Navbar/InviteTeamMembers";
import {
  clearSelectedCompanyKeys,
  clearSelectedKeys,
  setCheckAll,
  setCompanyCheckAll,
  setCompanyPagination,
  setpagination,
} from "../../../../store/action";
import { useDispatch, useSelector } from "react-redux";
import { leadPath } from "../../../../common/constants/pagepath/PagePath";
import UserProfile from "../../../Navbar/UserProfile";
import { apiErrorHandling } from "../../../../common/utils/helpers";

const { TabPane } = Tabs;

const ModalWrapper = styled.div`
  .ant-modal-content {
    width: 702px !important;
    background-color: yellow !important;
  }
`;

const TabContainerOverView = styled.div`
  .ant-tabs-tab-btn:hover {
    color: #000 !important;
  }
`;

export const LeadOverView = () => {
  const [leadData, setLeadData] = useState({});
  const [showRateLead, setShowRateLead] = useState(false);
  const [showInViteTeam, setShowInviteTeam] = useState(false);
  const [selectedRateValue, setSelectedRateValue] = useState(0);
  const [rateLeadShowModal, setRateLeadShowModal] = useState(false);
  const [comment, setComment] = useState("");
  const [leadDetails, setleadDetails] = useState([]);
  const [ratingHistoryData, setRatingHistoryData] = useState([]);
  const [showProfile, setShowProfile] = useState(false);
  const [profileData, setProfileData] = useState([]);
  const [admin, setAdmin] = useState(false);

  const dispatch = useDispatch();
  const pagination = useSelector((state) => state.pagination);
  const companyPagination = useSelector((state) => state.companypagination);

  const { id } = useParams();
  const lead_id = parseInt(id, 10);

  const navigate = useNavigate();

  const goBackToLeads = () => {
    fetchRatingHistory(lead_id);
    if (selectedRateValue === 0 && ratingHistoryData.length === 0) {
      setRateLeadShowModal(!rateLeadShowModal);
    } else {
      navigate(leadPath);
    }
  };

  const [activeKey, setActiveKey] = useState("1");
  const handleTabChange = (key) => {
    setActiveKey(key);
  };

  const handleLogout = () => {
    navigate("/");
    dispatch(clearSelectedKeys());
    dispatch(setCheckAll(false));
    dispatch(clearSelectedCompanyKeys());
    dispatch(setCompanyCheckAll(false));
    dispatch(setpagination(1, pagination.pagesize));
    dispatch(setCompanyPagination(1, companyPagination.pageSize));
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    localStorage.removeItem("admin");
  };

  const toggleProfile = () => {
    setShowProfile(!showProfile);
  };

  const menuItems = [
    {
      label: (
        <div
          onClick={(e) => {
            e.preventDefault();
            setShowProfile(true);
          }}
        >
          Profile
        </div>
      ),
      key: "0",
    },
    {
      label: <a href="/Change-password">Change Password</a>,
      key: "1",
    },
    {
      label: (
        <p
          onClick={(e) => {
            e.preventDefault();
            setShowInviteTeam(true);
          }}
        >
          Manage users
        </p>
      ),
      key: "2",
    },
    {
      label: <p onClick={handleLogout}>Logout</p>,
      key: "3",
    },
  ];

  const toggleForm = () => {
    setShowInviteTeam(!showInViteTeam);
  };

  const fetchLead = async (leadId) => {
    try {
      const response = await apiClient.get(`/profile/${leadId}`);
      setLeadData(response.data.profile);
      await fetchRatingHistory(leadId);
    } catch (error) {
      apiErrorHandling(error);
      console.error(error);
    }
  };

  const fetchLeadsFromSameCompany = async (lead_id) => {
    try {
      const response = await apiClient.get(`/other-leads/${lead_id}`);
      setleadDetails(response.data.data);
    } catch (err) {
      apiErrorHandling(err);
      console.error(err);
    }
  };

  const toggleRateLead = () => {
    fetchLead(lead_id);
    setShowRateLead(!showRateLead);
  };

  const toggleRateLeadShow = () => {
    setRateLeadShowModal(!rateLeadShowModal);
    fetchLead(lead_id);
  };

  const handleRateLead = async (selectedRate) => {
    try {
      if (selectedRate && ratingHistoryData.length !== 0) {
        setSelectedRateValue(selectedRate);
      }
      const response = await apiClient.post("/rate-lead-history", {
        rating: selectedRate,
        lead_id: lead_id,
        comment: comment.trim(),
      });

      if (response && !rateLeadShowModal) {
        toggleRateLead();
      }

      setComment("");
    } catch (error) {
      apiErrorHandling(error);
      console.error(error);
    }
  };

  const fetchRatingHistory = async (leadId) => {
    try {
      const response = await apiClient.get(`rate-lead-history/${leadId}`);

      if (ratingHistoryData.length === 0) {
        setSelectedRateValue(0);
      } else if (response.data?.data[0]?.rating > 0) {
        setSelectedRateValue(response.data?.data[0]?.rating);
      }
      setRatingHistoryData(response.data.data);
    } catch (err) {
      apiErrorHandling(err);
      console.error(err);
    }
  };

  useEffect(() => {
    fetchLeadsFromSameCompany(lead_id);
    setActiveKey("1");
    fetchLead(lead_id);
    const storedAdmin = localStorage.getItem("admin");
    setAdmin(JSON.parse(storedAdmin));
    window.scrollTo(0, 0);
  }, [lead_id]);

  const filteredItems = !admin
    ? menuItems.filter((item) => item.key !== "2")
    : menuItems;

  return (
    <div className="indivisual-detail-container">
      <div className="detail-wrapper">
        <div>
          <div className="back-to-leads-button" onClick={goBackToLeads}>
            <span className="back-to-lead-arrow">
              <LeftOutlined />
            </span>
            Back to leads
          </div>
        </div>
        <div className="profile-dropdown">
          <Dropdown
            menu={{
              items: filteredItems,
            }}
            trigger={["click"]}
          >
            <div onClick={(e) => e.preventDefault()}>
              <Image src={profile} preview={false} />
            </div>
          </Dropdown>
        </div>
      </div>
      <div className="detail-body-container">
        <div className="chlid-container sticky-container profile-container">
          <div className="lead-contact-container">
            <div className="icp-container">
              <div className="lead-personal-detail-container">
                <p className="lead-name">{leadData.name}</p>
                <p className="lead-job-title">{leadData.jobTitle}</p>
                <p className="lead-job-title">{leadData.location}</p>
              </div>
            </div>
            <div className="lead-contact-info-cont">
              <div className="lead-contact-logo">
                <Image src={emailIcon} preview={false} />
                <div className="lead-contact-info-txt">
                  <Tooltip title={leadData.email}>{leadData.email}</Tooltip>
                </div>
              </div>
              <div className="lead-contact-logo">
                <Image src={phone} preview={false} />
                <div className="lead-contact-info-phone">{leadData.mno}</div>
              </div>
            </div>
            <div className="lead-info-btns-cont">
              <div>
                <Button className="rate-lead-btn" onClick={toggleRateLead}>
                  <Image src={star} preview={false} />
                  <div className="rate-btn-text ">Rate lead</div>
                </Button>
              </div>
              {/* <div>
                <Button className="rate-lead-btn export-crm-btn">
                  <Image src={upArrow} preview={false} />
                  <div className="rate-btn-text export-crm-text">
                    Export to CRM
                  </div>
                </Button>
              </div> */}
            </div>
          </div>
          <div className="lead-source-wrapper">
            <p className="lead-source-lead">Lead Source</p>
            <p className="fi-europe">{leadData.event}</p>
          </div>
          {leadDetails.length === 0 ? (
            <></>
          ) : (
            <div className="leads-from-same-company-wrapper">
              <div>
                <p className="leads-from-same-cmpny">
                  Leads from the same company
                </p>
              </div>
              <div
                style={{
                  maxHeight: leadDetails.length > 3 ? "150px" : "auto",
                  overflowY: "auto",
                }}
                classname="lead-name-wrapper"
              >
                {leadDetails.map((item, index) => {
                  return (
                    <div key={index}>
                      <LeadsFromSameCompany
                        leadId={item.leadId}
                        leadName={item.leadName}
                        jobTitle={item.jobTitle}
                        isNavigateArrow={true}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>

        <div className="chlid-container activity-container">
          <LeadActivity />
          <div className="overViewTabBox">
            <TabContainerOverView>
              <Tabs activeKey={activeKey} onChange={handleTabChange}>
                <TabPane tab="Topic breakdown" key="1">
                  <Topicsbreakdown id={id} activeKey={activeKey} />
                </TabPane>
                <TabPane tab="Content consumption" key="2">
                  <ContentConsumption id={id} activeKey={activeKey} />
                </TabPane>
              </Tabs>
            </TabContainerOverView>
          </div>
        </div>
        <div className="chlid-container sticky-container">
          <ComapanyOverview
            companyName={leadData.company}
            industry={leadData.industry}
            location={leadData.location}
            leadId={lead_id}
            leadName={leadData.name}
            // employeeSize={leadData.employeeSize}
          />
        </div>
      </div>
      <ModalWrapper>
        <RateLeadModal
          showRateLead={showRateLead}
          toggleRateLead={toggleRateLead}
          handleRateLead={(selectedRate) => handleRateLead(selectedRate)}
          leadName={leadData.name}
          comment={comment}
          leadId={lead_id}
          setComment={setComment}
          leadRate={selectedRateValue}
          ratingHistoryData={ratingHistoryData}
        />

        <RateLeadModal
          showRateLead={rateLeadShowModal}
          toggleRateLead={toggleRateLeadShow}
          handleRateLead={(selectedRate) => handleRateLead(selectedRate)}
          leadName={leadData.name}
          modalType="backToLead"
          comment={comment}
          leadId={lead_id}
          setComment={setComment}
          leadRate={selectedRateValue}
          ratingHistoryData={ratingHistoryData}
        />
      </ModalWrapper>

      <UniqueModal
        showModal={showInViteTeam}
        onCancel={toggleForm}
        message={"Manage Users"}
        closable={false}
        ModalWidth={702}
      >
        <InviteTeamMembers />
      </UniqueModal>

      <UniqueModal
        showModal={showProfile}
        onChange={toggleProfile}
        message={"My profile"}
        closable={false}
        onCancel={toggleProfile}
        ModelHeight={"800"}
      >
        <UserProfile onCancelButton={toggleProfile} />
      </UniqueModal>
    </div>
  );
};
