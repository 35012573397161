import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Login from "../Dashboard/Login/Login";
import Registration from "../Dashboard/Registration/Registration";
import { Navbar } from "../Navbar/Navbar";
import ChangePassword from "../Dashboard/Login/ChangePassword";
import { LeadOverView } from "../Leads/IndividualLeads/leadsOverview/LeadOverView";

function Approute() {
  return (
    <div>
      <Routes>
        <Route path="/Registration" element={<Registration />} />
        <Route path="/" element={<Login />} />
        <Route path="/Lead/:id" element={<LeadOverView />} />
        <Route path="/Change-password" element={<ChangePassword />} />

        <Route path="/leads" element={<Navbar />}>
          <Route path="" element={<Navigate to="/leads/individual" />} />
        </Route>

        <Route path="/leads/individual" element={<Navbar />} />
        <Route path="/leads/company" element={<Navbar />} />
        <Route path="/dashboard" element={<Navbar />} />
      </Routes>
    </div>
  );
}

export default Approute;
