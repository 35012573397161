import React from "react";
import LeadActivityImg from "./overViewtabs/LeadActivityImg";

export const LeadActivity = () => {
  return (
    <>
      <div className="lead-activity-wrapper">
        <div className="lead-activity-title-container">
          <LeadActivityImg />
        </div>
      </div>
    </>
  );
};
