import React, { useEffect, useState } from "react";
import "./FilterContainer.style.scss";
import { Space, Input, Button, Image } from "antd";
import CollapseableFilterOptions from "./CollapseableFilterOptions";
import filterIcon from "../../../../assets/icons/filterIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  clearSelectedCompanyKeys,
  clearSelectedKeys,
  setCheckAll,
  setCompanyCheckAll,
  setCompanyPagination,
  setpagination,
} from "../../../../store/action";
import { leadPath } from "../../../../common/constants/pagepath/PagePath";

const { Search } = Input;
function FilterContainer({
  filterData,
  selectedItems,
  setSelectedItems,
  activeKeyIndicator,
}) {
  const [selectedItems1, setSelectedItems1] = useState({ ...selectedItems });
  const [name, setname] = useState("");
  const [clearAll, setClear] = useState(false);
  const dispatch = useDispatch();
  const pagination = useSelector((state) => state.pagination);
  const companyPagination = useSelector((state) => state.companypagination);

  const handleSelectedItem = () => {
    setSelectedItems({ ...selectedItems1, searchName: name });
    dispatch(clearSelectedKeys());
    dispatch(setCheckAll(false));
    dispatch(clearSelectedCompanyKeys());
    dispatch(setCompanyCheckAll(false));
    dispatch(setpagination(1, pagination.pagesize));
    dispatch(setCompanyPagination(1, companyPagination.pageSize));
  };

  const handleClearSelectedItem = () => {
    setClear(true);
    setSelectedItems1({
      searchName: "",
      jobtitle: "",
      topic: "",
      country: "",
      nature_of_business: "",
      campaign: "",
    });

    setSelectedItems({
      searchName: "",
      jobtitle: "",
      topic: "",
      country: "",
      nature_of_business: "",
      campaign: "",
    });
    setname("");
    setTimeout(() => {
      setClear(false);
    }, 1000);
    dispatch(clearSelectedKeys());
    dispatch(setCheckAll(false));
    dispatch(clearSelectedCompanyKeys());
    dispatch(setCompanyCheckAll(false));
    dispatch(setpagination(1, pagination.pagesize));
    dispatch(setCompanyPagination(1, companyPagination.pageSize));
  };

  const handleNameSearch = (value, event) => {
    if (
      event &&
      event.target &&
      typeof event.target.className === "string" &&
      event.target.className.includes("ant-input-clear-icon")
    ) {
      setname("");
      setSelectedItems({ ...selectedItems, searchName: "" });
    } else {
      dispatch(clearSelectedKeys());
      dispatch(setCheckAll(false));
      dispatch(clearSelectedCompanyKeys());
      dispatch(setCompanyCheckAll(false));
      dispatch(setpagination(1, pagination.pagesize));
      dispatch(setCompanyPagination(1, companyPagination.pageSize));
      setname(value);
      setSelectedItems({ ...selectedItems1, searchName: value });
    }
  };
  const handleChange = (e) => {
    const value = e.target.value;
    if (value === "" || value[0] !== " ") {
      setname(value);
      value.split(" ").filter((terms) => terms.trim() !== "");
      setSelectedItems1({ ...selectedItems1, searchName: value });
    }
  };

  useEffect(() => {
    setname("");
    setSelectedItems({
      searchName: "",
      jobtitle: "",
      topic: "",
      country: "",
      nature_of_business: "",
      campaign: "",
    });
    setSelectedItems1({
      searchName: "",
      jobtitle: "",
      topic: "",
      country: "",
      nature_of_business: "",
      campaign: "",
    });
  }, [activeKeyIndicator]);

  return (
    <div
      className="filter-container"
      style={{
        gap: 30,
      }}
    >
      <div style={{ paddingLeft: "14px", paddingRight: "14px" }}>
        <h1 className="filter-title">Filter Your Search</h1>
        <Space direction="vertical" className="customInput">
          <Search
            placeholder={
              activeKeyIndicator === leadPath
                ? "Search by lead Name"
                : "Search by company name"
            }
            enterButton
            value={name}
            onChange={handleChange}
            onSearch={handleNameSearch}
            allowClear
          />
        </Space>
      </div>

      <div className="collaps-container">
        <CollapseableFilterOptions
          filterData1={filterData}
          selectedItems1={selectedItems1}
          setSelectedItems1={setSelectedItems1}
          activeKeyIndicator1={activeKeyIndicator}
          clearAll={clearAll}
        />
      </div>

      <div className="button-container">
        <h5 className="clear-button" onClick={handleClearSelectedItem}>
          Clear All
        </h5>

        <Button className="filter-button">
          <div onClick={handleSelectedItem}>
            <Image src={filterIcon} preview={false} />
            <span>Apply Filter</span>
          </div>
        </Button>
      </div>
    </div>
  );
}

export default FilterContainer;
