import React, { useEffect, useState } from "react";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Doughnut } from "react-chartjs-2";
import "./JobFunction.style.scss";
import { apiClient } from "../../api/apiService";
import { apiErrorHandling } from "../../common/utils/helpers";

Chart.register(CategoryScale, ChartDataLabels);

function NatureDistribution() {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "",
        data: [],
        backgroundColor: [],
        borderWidth: 1,
      },
    ],
  });

  const jobDistrubtionfetchData = async () => {
    try {
      const response = await apiClient.get(`dashboard/businessdistribution`);
      const colors = [
        "#11A7D9",
        "#FFD66B",
        "#FF8F6B",
        "#cc7255",
        "#e0aa99",
        "#CCAB56",
        "#ABCC56",
        "#AB56CC",
        "#56CCAB",
        "#CC56AB",
        "#AC809F",
        "#AC9F80",
        "#99cfe0",
        "#E2B19E",
      ];
      const bgcolor = [];
      for (let i = 1; i <= 15; i++) {
        bgcolor.push(colors[i - 1]);
      }
      setChartData({
        labels: response.data.data.map((item) => item.industry),
        datasets: [
          {
            label: "",
            data: response.data.data.map((item) => item.lead_count),
            backgroundColor: bgcolor,
            borderWidth: 1,
            spacing: -10,
            borderRadius: 5,
            cutout: 50,
          },
        ],
      });
    } catch (error) {
      apiErrorHandling(error);
      console.error(error);
    }
  };

  useEffect(() => {
    jobDistrubtionfetchData();
  }, []);

  const options = {
    scales: {
      x: {
        grid: {
          display: false,
          borderColor: "#ffffff",
        },
        ticks: {
          display: false,
        },
      },

      y: {
        grid: {
          display: false,
          borderColor: "#ffffff",
        },
        ticks: {
          display: false,
        },
      },
    },
    plugins: {
      datalabels: {
        display: false,
      },
      title: {
        display: false,
        text: "",
      },
      labels: {
        offset: 20,
        formatter: (labels) => labels.join("\n"),
      },
      legend: {
        display: true,
        // position: "right",
        // fullWidth: true,
        // maxWidth: "250",
        labels: {
          usePointerStyle: true,
          boxWidth: 12,
          // padding: 20,
          textAlign: "left",
          font: {
            size: 14,
            weight: 500,
            color: "#030229",
          },
        },
        maxWidth: 200,
      },
      afterFit: (legend) => {
        legend.width = 50;
      },
    },
    responsive: true,
    aspectRatio: 1.6,
    maintainAspectRatio: false,
  };

  return (
    <div className="nature-div">
      <p className="nature-title">Nature of business distribution</p>
      <div className="chart-container">
        <Doughnut data={chartData} options={options} />
      </div>
    </div>
  );
}

export default NatureDistribution;
